export function defaultFilter() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    // if (mm == 0) {
    //     yyyy = yyyy - 1
    //     mm = 12
    // }
    // dd = new Date(yyyy, mm, 0).getDate()
    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }
    const currenDate = yyyy + '-' + mm + '-' + dd
    const startdate = yyyy + '-' + "01" + '-' + "01"
    return { currenDate, startdate }
}