import React, { Component } from 'react';

const COLORS = ['#bd4242', '#eb7070', '#19493e', '#e9fdf7', '#b4f2dc', '#57b5a4', '#bd4242', '#eb7070', '#19493e'];

class ChartDetails extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { graphData } = this.props
        return (
            <React.Fragment>
                {Object.keys(graphData).length ? graphData['INCOME'] && <div className="row mx-0 justify-content-md-center">
                    <div className="col-md-10 col-sm-12">
                        <div className="graph-label-container">
                            <h4>Income Summary</h4>
                            <div className="graph-labels">
                                {graphData["INCOME"].map((_, key) => (
                                    <div className="gl-contain" key={key}>
                                        <div className="gl-icon" style={{ backgroundColor: COLORS[key % COLORS.length] }}></div>
                                        <label>{_.category.name}<span className="ml-3">{((_.amount * 100) / graphData["INCOME"].reduce((curr, next) => curr + parseFloat(next.amount), 0)).toFixed(2)}%</span></label>
                                        <span>{_.amount && _.amount.toFixed(2)}</span>
                                    </div>
                                ))}
                            </div>
                            <div className="glc-footer gl-contain">
                                <label>Total</label>
                                <span>{graphData["INCOME"].reduce((curr, next) => curr + parseFloat(next.amount), 0).toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                </div> : null}
                {Object.keys(graphData).length ? graphData["EXPENSE"] && <div className="row mx-0 justify-content-md-center mt-sm-5">
                    <div className="col-md-10 col-sm-12">
                        <div className="graph-label-container">
                            <h4>Expense Summary</h4>
                            <div className="graph-labels">
                                {graphData["EXPENSE"].map((_, key) => (
                                    <div className="gl-contain" key={key}>
                                        <div className="gl-icon" style={{ backgroundColor: COLORS[key % COLORS.length] }}></div>
                                        <label>{_.category.name}<span className="ml-3">{((_.amount * 100) / graphData["EXPENSE"].reduce((curr, next) => curr + parseFloat(next.amount), 0)).toFixed(2)}%</span></label>
                                        <span>{_.amount && _.amount.toFixed(2)}</span>
                                    </div>
                                ))}
                            </div>
                            <div className="glc-footer gl-contain">
                                <label>Total</label>
                                <span>{graphData["EXPENSE"].reduce((curr, next) => curr + parseFloat(next.amount), 0).toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                </div> : null}
            </React.Fragment>
        )
    }
}
export default ChartDetails
