import {getAPIUrl} from "../Global";
import {Post} from "../headerIntercepter";
import {setUserToken} from "../localStorageHandler";
import {history} from "../history";
import {routes} from "../routes";

export function login(data) {
    const url = getAPIUrl('auth.login');
    return Post(url, data, false)
        .then(response => {
            setUserToken(response.data.token);
            history.push(routes.dashboard.self)
        })
}