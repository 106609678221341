import React, { Component } from 'react';
import { Button, message, Modal, Select, Checkbox } from 'antd';
import { connect } from "react-redux";
import { getCategory, getTransResponsibility } from "../../Controller/API/PaymentAPI";
import {
    resetFilters,
    updateExpenseCategoryFilter,
    updateIncomeCategoryFilter, updateStatsType, updateAssetType, updateLiquidityType, updateInitialAmountChecked,
    updateTransactionAccountFilter, updateTransactionReponsibilityFilter
} from "../../Redux/Actions/filterAction";
import { getStatements } from "../../Redux/Actions/statementAction";
import { ConsoleSqlOutlined } from '@ant-design/icons';

const { Option } = Select;

class FilterModalNew extends Component {
    state = {
        categories: [],
        incomeCategory: undefined,
        expenseCategory: undefined,
        transAccount: undefined,
        stats_type: undefined,
        asset_type: undefined,
        liquidity_type: undefined,
        transRespAccount: undefined,
        transResponsibility: [],
        initial_amount_checked: true
    };

    componentDidMount() {
        this.fetchCategory();
        this.fetchTransResponsibility();
        this.setState({
            transAccount: this.props.filter.transaction_account_list ? this.props.filter.transaction_account_list.split(',').map(i => parseInt(i)) : undefined,
            transRespAccount: this.props.filter.transaction_responsibility_list ? this.props.filter.transaction_responsibility_list.split(',').map(i => parseInt(i)) : undefined,
            incomeCategory: this.props.filter.category_income_list ? this.props.filter.category_income_list.split(',').map(i => parseInt(i)) : undefined,
            expenseCategory: this.props.filter.category_expense_list ? this.props.filter.category_expense_list.split(',').map(i => parseInt(i)) : undefined,
            stats_type: this.props.filter.stats_type ? this.props.filter.stats_type : undefined,
            asset_type: this.props.filter.asset_type ? this.props.filter.asset_type : undefined,
            liquidity_type: this.props.filter.liquidity_type ? this.props.filter.liquidity_type : undefined,
            initial_amount_checked: this.props.filter.initial_amount_checked ? this.props.filter.initial_amount_checked : true,
        })
    }

    fetchCategory = () => {
        getCategory(null).then(res => {
            this.setState({ categories: res?.data })
        }).catch(e => {
            message.error("Error while fetching categories")
        })
    };
    fetchTransResponsibility = () => {
        getTransResponsibility().then(response => {
            this.setState({ transResponsibility: response.data })
        }).catch(err => {
            message.error('Something went wrong')
        })
    };

    onExpenseChange = (data) => {
        this.setState({ expenseCategory: data })
    };
    onIncomeChange = (data) => {
        this.setState({ incomeCategory: data })
    };
    transChange = (data) => {
        this.setState({ transAccount: data })
    };
    transRespChange = (data) => {
        this.setState({ transRespAccount: data })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filter !== this.props.filter) {
            this.setState({
                incomeCategory: this.props.filter.category_income_list ? this.props.filter.category_income_list.split(',').map(i => parseInt(i)) : undefined,
                expenseCategory: this.props.filter.category_expense_list ? this.props.filter.category_expense_list.split(',').map(i => parseInt(i)) : undefined,
                transAccount: this.props.filter.transaction_account_list ? this.props.filter.transaction_account_list.split(',').map(i => parseInt(i)) : undefined,
                stats_type: this.props.filter.stats_type ? this.props.filter.stats_type : undefined,
                asset_type: this.props.filter.asset_type ? this.props.filter.asset_type : undefined,
                liquidity_type: this.props.filter.liquidity_type ? this.props.filter.liquidity_type : undefined,
                initial_amount_checked: this.props.filter.initial_amount_checked ? this.props.filter.initial_amount_checked : true,
                transRespAccount: this.props.filter.transaction_responsibility_list ? this.props.filter.transaction_responsibility_list.split(',').map(i => parseInt(i)) : undefined
            })
        }
    }

    applyFilter = async () => {
        let { incomeCategory, expenseCategory, transAccount, transRespAccount, stats_type, asset_type, liquidity_type, initial_amount_checked } = this.state;
        await this.props.updateIncomeCategoryFilter(incomeCategory ? incomeCategory.join(',') : null);
        await this.props.updateExpenseCategoryFilter(expenseCategory ? expenseCategory.join(',') : null);
        await this.props.updateTransactionAccountFilter(transAccount ? transAccount.join(',') : null);
        await this.props.updateTransactionReponsibilityFilter(transRespAccount ? transRespAccount.join(',') : null);
        await this.props.updateStatsType(stats_type ? stats_type : null);
        await this.props.updateAssetType(asset_type ? asset_type : null);
        await this.props.updateLiquidityType(liquidity_type ? liquidity_type : null);
        await this.props.updateInitialAmountChecked(initial_amount_checked ? 1 : 0);
        await this.props.getStatements(this.props.filter, this.props.cancelSource)
    };

    render() {
        return (
            <Modal className="filter-modal-main"
                title="Filter"
                visible={this.props.visible}
                onOk={this.props.onClose}
                onCancel={this.props.onClose}
                width="40%"
                footer={[
                    <Button key="back" onClick={async () => {
                        await this.props.resetFilters();
                        await this.props.getStatements(this.props.filters, this.props.cancelSource)
                    }}>
                        Reset Filter
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.applyFilter}>
                        Apply Filter
                    </Button>,
                ]}
            >
                <div className="fmm-contain">
                    <div className="fmmc-each-item">
                        <span>Transaction Type</span>
                        <div id="tranastion_type">
                            <Select placeholder="Transaction Type" style={{ width: '100%' }} value={this.state.stats_type}
                                onChange={e => this.setState({ stats_type: e })}
                                getPopupContainer={() => document.getElementById('tranastion_type')}>
                                <Option value="INCOME">INCOME</Option>
                                <Option value="EXPENSE">EXPENSE</Option>
                            </Select>
                        </div>
                    </div>
                    <div className="fmmc-each-item">
                        <span>Asset Type</span>
                        <div id="asset_type">
                            <Select placeholder="Asset Type" style={{ width: '100%' }} value={this.state.asset_type}
                                onChange={e => this.setState({ asset_type: e })}
                                getPopupContainer={() => document.getElementById('asset_type')}>
                                <Option value="DEBT">Debt</Option>
                                <Option value="ASSET">Asset</Option>
                            </Select>
                        </div>
                    </div>
                    <div className="fmmc-each-item">
                        <span>Liquidity Type</span>
                        <div id="liquidity_type">
                            <Select placeholder="Liquidity Type" style={{ width: '100%' }} value={this.state.liquidity_type}
                                onChange={e => this.setState({ liquidity_type: e })}
                                getPopupContainer={() => document.getElementById('liquidity_type')}>
                                <Option value="LIQUID">Liquid</Option>
                                <Option value="ILLIQUID">Illiquid</Option>
                            </Select>
                        </div>
                    </div>
                    <div className="fmmc-each-item">
                        <span>Transaction Accounts</span>
                        <div id="account_type">
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                showSearch
                                optionFilterProp="children"
                                placeholder="Please select"
                                onChange={this.transChange}
                                value={this.state.transAccount || []}
                                getPopupContainer={() => document.getElementById('account_type')}
                            >
                                {this.props.transAccount.map(item => (
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="fmmc-each-item">
                        <span>Transaction holder</span>
                        <div id="trasn_resp_type">
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                showSearch
                                optionFilterProp="children"
                                placeholder="Please select"
                                onChange={this.transRespChange}
                                value={this.state.transRespAccount || []}
                                getPopupContainer={() => document.getElementById('trasn_resp_type')}
                            >
                                {this.state.transResponsibility.map(item => (
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="fmm-contain">
                    <label className="filter-label">Income </label>
                    <div className="fmmc-each-item">
                        <span>Categories</span>
                        <div id="category_type">
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                showSearch
                                optionFilterProp="children"
                                value={this.state.incomeCategory || []}
                                onChange={this.onIncomeChange}
                                getPopupContainer={() => document.getElementById('category_type')}
                            >
                                {this?.state?.categories?.length&&this.state.categories.filter(item => item.stats_type === "INCOME").map(x => (
                                    <Option key={x.id} value={x.id}>{x.name}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="fmm-contain">
                    <label className="filter-label">Expenses</label>
                    <div className="fmmc-each-item">
                        <span>Categories</span>
                        <div id="expense_type">
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                showSearch
                                optionFilterProp="children"
                                onChange={this.onExpenseChange}
                                value={this.state.expenseCategory || []}
                                getPopupContainer={() => document.getElementById('expense_type')}
                            >
                                {this?.state?.categories?.length&&this.state.categories.filter(item => item.stats_type === "EXPENSE").map(x => (
                                    <Option key={x.id} value={x.id}>{x.name}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="fmm-contain">
                    <Checkbox onClick={(e) => { this.setState({ initial_amount_checked: e.target.checked})}} checked={this.state.initial_amount_checked}>initial amount</Checkbox>
                </div>
            </Modal>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        filter: state.filters,
        transAccount: state.transAccount,
        cancelSource: state.statements.cancelSource
    }
};
export default connect(mapStateToProps, {
    updateIncomeCategoryFilter,
    updateExpenseCategoryFilter,
    updateTransactionAccountFilter,
    updateTransactionReponsibilityFilter,
    getStatements,
    resetFilters,
    updateStatsType,
    updateAssetType,
    updateLiquidityType,
    updateInitialAmountChecked
})(FilterModalNew)
