import {ADD_ENTITY, GET_ENTITY} from "../Actions/actionType";

const initialState = [];

export default function entityReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ENTITY:
            state = [...action.payload];
            return state;
        case ADD_ENTITY:
            state = [...state, ...action.payload];
            return state;
        default:
            return state;
    }
}