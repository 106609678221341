import React, { Component } from 'react';
import { Button, Form, Input, message } from "antd";
import { login } from "../Controller/API/AuthAPI";
import logo_image from "../assets/img/login-logo.svg"

class Login extends Component {

    onSubmit = (values) => {
        login(values)
            .catch(err => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
    };

    render() {
        return (
            <React.Fragment>
                <div className="login-main-fluid login-container container-fluid h-100">
                    <div className="row h-100">
                        <div className="login-logo-div col-12 text-left col-sm-12 col-md-6 col-lg-6">
                            <img alt="logo" src={logo_image} />
                        </div>
                        <div className="login-logo-details col-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="row login-details-inner text-center pad-0">
                                <div className="form-row mx-0 bg-white">
                                    <div className="col-12">
                                        <h6 className="text-center mb-5">Log In</h6>
                                    </div>
                                    <div className="col-12 form-items">
                                        <Form
                                            onFinish={this.onSubmit}
                                        >
                                            <div className="form-group position-relative">
                                                <label>Email</label>
                                                <Form.Item
                                                    name={'email'}
                                                    rules={[{
                                                        type: 'email',
                                                        message: 'Please Provide valid Email Id'
                                                    }, { required: 'true', message: "Email is Required" }]}
                                                >
                                                    <Input placeholder={'Email'} />
                                                </Form.Item>
                                                {/*<label className="mb-0">Email</label>*/}
                                                {/*<input type="email" required*/}
                                                {/*       className="form-control font-weight-normal" placeholder=" "/>*/}
                                                {/*<label className="mb-0">Email</label>*/}
                                            </div>
                                            <div className="form-group position-relative">
                                                <label>Password</label>
                                                <Form.Item
                                                    name={'password'}
                                                    rules={[{ required: 'true', message: "Password is Required" }]}
                                                >
                                                    <Input type={'password'} placeholder={'Password'} />
                                                </Form.Item>
                                                {/*<label className="mb-0">Password</label>*/}
                                                {/*<input type="password" required*/}
                                                {/*       className="form-control font-weight-normal" placeholder=" "/>*/}
                                                {/*<label className="mb-0">Password</label>*/}
                                            </div>
                                            <div className="form-group mb-0">
                                                <Form.Item>
                                                    <Button
                                                        htmlType="submit"
                                                        className="text-uppercase border-0 text-white w-100 font-weight-bold">LOG
                                                            IN</Button>
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Login;