import {addEntity, getEntity} from "../../Controller/API/PaymentAPI";
import {ADD_ENTITY, GET_ENTITY} from "./actionType";


export const getEntityAction = () => {
    return async dispatch => {
        getEntity().then(response => {
            dispatch({
                type: GET_ENTITY,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_ENTITY,
                payload: []
            })
        })
    }
};

export const addEntityAction = (data) => {
    return async dispatch => {
        addEntity(data).then(response => {
            dispatch({
                type: ADD_ENTITY,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: ADD_ENTITY,
                payload: []
            })
        })
    }
};