export const GET_STATEMENTS = 'GET_STATEMENTS';
export const GET_STATEMENTS_SUCCESS = 'GET_STATEMENTS_SUCCESS';
export const CANCEL_SOURCE = "CANCEL_SOURCE"
//account actions
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';

//entity Actions
export const GET_ENTITY = 'GET_ENTITY';
export const ADD_ENTITY = 'ADD_ENTITY';

//entity Actions
export const GET_TRANS_ACCOUNT = 'GET_TRANS_ACCOUNT';
export const ADD_TRANS_ACCOUNT = 'ADD_TRANS_ACCOUNT';


//Filter Action
export const RESET_FILTERS = 'RESET_FILTERS';
export const GET_ALL_FILTER = 'GET_ALL_FILTER';
export const UPDATE_ACCOUNT_FILTER = 'UPDATE_ACCOUNT_FILTER';
export const UPDATE_ENTITY_FILTER = 'UPDATE_ENTITY_FILTER';
export const UPDATE_DATE_FILTER = 'UPDATE_DATE_FILTER';
export const UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER';
export const UPDATE_STATS_TYPE = 'UPDATE_STATS_TYPE';
export const UPDATE_ASSET_TYPE = 'UPDATE_ASSET_TYPE';
export const UPDATE_INITIAL_AMOUNT_CHECKED = 'UPDATE_INITIAL_AMOUNT_CHECKED';
export const UPDATE_LIQUIDITY_TYPE = 'UPDATE_LIQUIDITY_TYPE';
export const UPDATE_INCOME_CATEGORY_FILTER = 'UPDATE_INCOME_CATEGORY_FILTER';
export const UPDATE_EXPENSE_CATEGORY_FILTER = 'UPDATE_EXPENSE_CATEGORY_FILTER';
export const UPDATE_TRANSACTION_ACCOUNT_LIST = 'UPDATE_TRANSACTION_ACCOUNT_LIST';
export const UPDATE_TRANSACTION_RESPONSIBILITY_LIST = 'UPDATE_TRANSACTION_RESPONSIBILITY_LIST';
