import React, { Component } from 'react';
import { Layout, Checkbox, message, Spin } from "antd";
import { Image as Images } from "./Images";
import { Link } from "react-router-dom";
import moment from "moment";
import {
    getAccounts,
    getCategory,
    getEntity,
    getStatementById,
    getTransAccount, getTransResponsibility
} from "../Controller/API/PaymentAPI";
import { reverse } from "named-urls";
import { routes } from "../Controller/routes";
import { methods } from "../Controller/Global";

const { Content } = Layout;

function onChange(e) {
    // console.log(`checked = ${e.target.checked}`);
}


class IncomeTitle extends Component {
    state = {
        categories: [],
        entities: [],
        accounts: [],
        transAccount: [],
    };

    componentDidMount() {
        getStatementById(this.props.match.params.id).then(response => {
            this.setState({ ...response.data })
        }).catch(err => {
            console.log(err);
            message.error('Error while fetching statement')
        });
        this.fetchCategory(this.props.match.params.type);
        this.fetchAccount();
        this.fetchEntity();
        this.fetchTransAccount();
        this.fetchTransResponsibility()
    }

    fetchAccount = () => {
        getAccounts().then(response => {
            this.setState({ accounts: response.data })
        }).catch(err => {
            message.error('Something went wrong')
        })
    };
    fetchCategory = (type) => {
        let data = { stats_type: type.toUpperCase() };
        getCategory(data).then(response => {
            this.setState({ categories: response.data })
        }).catch(err => {
            message.error('Something went wrong')
        })
    };
    fetchEntity = () => {
        getEntity().then(response => {
            this.setState({ entities: response.data })
        }).catch(err => {
            message.error('Something went wrong')
        })
    };
    fetchTransAccount = () => {
        getTransAccount().then(response => {
            this.setState({ transAccount: response.data })
        }).catch(err => {
            message.error('Something went wrong')
        })
    };
    fetchTransResponsibility = () => {
        getTransResponsibility().then(response => {
            this.setState({ transResponsibility: response.data })
        }).catch(err => {
            message.error('Something went wrong')
        })
    };

    render() {
        let { categories, entities, accounts, transaction_account, stats_type, transaction_responsibility } = this.state;
        if (!this.state.id) {
            return <Layout
                className="site-layout site-layout-main ant-layout-main-second bg-white h-100 w-100 d-inline-block">
                <div className={'text-center mt-5'}><Spin /></div>
            </Layout>
        }
        return (
            <Layout className="site-layout site-layout-main ant-layout-main-second bg-white h-100 w-100 d-inline-block">
                <Content>
                    <div className="site-layout-background bg-white">
                        <div className="row heading-header-row">
                            <div className="col-md-6 col-sm-6 col-8 col-lg-6">
                                <h5 className="mb-0 font-weight-bold">{moment(this.state.date).format('ddd, MMM DD, YYYY')}</h5>
                            </div>
                            <div className="col-md-6 col-sm-6 col-4 col-lg-6">
                                <div className="row mx-0 justify-content-end">
                                    <Link to={reverse(routes.dashboard.payment.method, {
                                        type: this.props.match.params.type,
                                        method: methods.edit,
                                        id: this.props.match.params.id
                                    })}
                                        className="bg-transparent ant-btn flex-align-center-center border-0 text-uppercase rounded-0 shadow-none">
                                        <img src={Images.edit_icon} className="img-fluid mr-1" alt="edit icon" />
                                        EDIT</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row title-details-row">
                            <div className="col-sm-4 col-6 col-md-4 col-lg-4 my-3">
                                <h6 className="w-100 mb-1">Category</h6>
                                <p className="mb-0">{categories.length > 0 && categories.filter(x => x.id === this.state.category.id)[0].name}</p>
                            </div>
                            <div className="col-sm-4 col-6 col-md-4 col-lg-4 my-3">
                                <h6 className="w-100 mb-1">Transaction Account</h6>
                                <p className="mb-0">{transaction_account ? transaction_account.name : "-"}</p>
                            </div>
                            <div className="col-sm-4 col-6 col-md-4 col-lg-4 my-3">
                                <h6 className="w-100 mb-1">Transaction Type</h6>
                                <p className="mb-0">{stats_type ? stats_type : "-"}</p>
                            </div>
                            <div className="col-sm-4 col-6 col-md-4 col-lg-4 my-3">
                                <h6 className="w-100 mb-1">Transaction Responsibility</h6>
                                <p className="mb-0">{transaction_responsibility ? transaction_responsibility.name : "-"}</p>
                            </div>
                            <div className="col-sm-4 col-6 col-md-4 col-lg-4 my-3">
                                <h6 className="w-100 mb-1">Bank Account</h6>
                                <p className="mb-0">{accounts.length > 0 && accounts.filter(x => x.id === this.state.account.id)[0].title}</p>
                            </div>
                            <div className="col-sm-4 col-6 col-md-4 col-lg-4 my-3">
                                <h6 className="w-100 mb-1">Entity</h6>
                                <p className="mb-0">{entities.length > 0 && entities.filter(x => x.id === this.state.entity.id)[0].name}</p>
                            </div>
                            <div className="col-12 my-3 col-md-4">
                                <h6 className="w-100 mb-1">Description</h6>
                                <p className="mb-0">{this.state.description}</p>
                            </div>
                            <div className="col-12 my-3">
                                <Checkbox checked={this.state.active_entry} onChange={onChange}>Active Entry</Checkbox>
                            </div>
                            <div className="col-sm-3 col-12  my-3">
                                <h6 className="w-100 mb-1">Date</h6>
                                <p className="mb-0">{moment(this.state.date).format('ddd, MMM DD, YYYY')}</p>
                            </div>
                            <div className="col-sm-3 col-12  my-3">
                                <h6 className="w-100 mb-1">Amount</h6>
                                <p className="mb-0">{this.state.amount}</p>
                            </div>
                            <div className="col-sm-3 col-12  my-3">
                                <h6 className="w-100 mb-1">Repeat</h6>
                                <p className="mb-0">Never</p>
                            </div>
                            <div className="col-sm-3 col-12 my-3">
                                <h6 className="w-100 mb-1">Memo</h6>
                                <p className="mb-0">{this.state.memo}</p>
                            </div>
                            {/*<div className="col-12">*/}
                            {/*    <div className="row mx-0 listing-table table-responsive">*/}
                            {/*        <Table*/}
                            {/*            className="responsive-table table table-hover table-custom"*/}
                            {/*            columns={columns}*/}
                            {/*            dataSource={data}*/}
                            {/*            bordered*/}
                            {/*            pagination={false}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </Content>
            </Layout>
        );
    }
}

export default IncomeTitle;