import {getStatement} from "../../Controller/API/PaymentAPI";
import * as constant from './actionType';
import axios from "axios";

export const getStatements = (filter = {}, cancelSource = undefined) => {
    if (typeof cancelSource === "object") {
        console.log(cancelSource, typeof cancelSource)
        cancelSource.cancel('Operation canceled by the user.');
    }
    return async dispatch => {
        dispatch({
            type: constant.GET_STATEMENTS,
        })
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        dispatch({
            type: constant.CANCEL_SOURCE,
            cancelSource: source
        })
        getStatement(filter, source.token)
            .then(response => {
                dispatch({
                    type: constant.GET_STATEMENTS_SUCCESS,
                    payload: response.data,
                })
            }).catch(err => {
            if (axios.isCancel(err)) {
                console.log('Request canceled', err.message);
            } else {
                // handle error
            }
        })

    }
};
