import React, { Component } from 'react';
import { Button, DatePicker, Dropdown, Input, Layout, Menu, Switch, Tooltip } from "antd";
import moment from 'moment';
import { Image as Images } from "./Images";
import FilterModalNew from './modal/FilterModalNew';
import { connect } from "react-redux";
import { updateDateFilter, updateSearchFilter } from "../Redux/Actions/filterAction";
import { getStatements } from "../Redux/Actions/statementAction";
import { routes } from "../Controller/routes";
import { Link } from "react-router-dom";
import { PieChartFilled, UnorderedListOutlined } from '@ant-design/icons';
import { ReloadOutlined } from "@ant-design/icons";


const menu = (
    <Menu>
        <Menu.Item key="0">
            <a href="#">Profile</a>
        </Menu.Item>
        <Menu.Item key="1">
            <Link to={routes.logout}>Logout</Link>
        </Menu.Item>
    </Menu>
);
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
const { Search } = Input;
const { Header } = Layout;

class CustomHeader extends Component {
    state = {
        visible: false,
        startDate: "",
        endDate: "",
        resetDate:false
    };
    async componentDidMount() {
        var end_date = new Date();
        end_date.setDate(end_date.getDate())
        let year = end_date.getFullYear()
        let start_date = new Date(year, "0", "1")
        let date = {};
        date.start = moment(start_date).format("YYYY-MM-DD");
        date.end = moment(end_date).format("YYYY-MM-DD");
        this.setState({ startDate: date.start, endDate: date.end })
        await this.props.updateDateFilter(date);
        await this.props.getStatements(this.props.filters, this.props.cancelSource)
    }
    visibleFilter = (visible) => {
        this.setState({
            visible: visible,
        })
    };
    searchFilter = async (text) => {
        await this.props.updateSearchFilter(text === "" ? null : text);
        await this.props.getStatements(this.props.filters, this.props.cancelSource)
    };


    dateChange = async (data) => {
        this.setState({resetDate:false})
        let date = {};
        if(data){
            date.start = moment(data[0]).format("YYYY-MM-DD");
            date.end = moment(data[1]).format("YYYY-MM-DD");
        this.setState({ startDate: moment(data[0]).format("YYYY-MM-DD"), endDate: moment(data[1]).format("YYYY-MM-DD") })
        }else{
            date.start = null;
            date.end = null;
            this.setState({resetDate:true})
        }
        await this.props.updateDateFilter(date);
        await this.props.getStatements(this.props.filters, this.props.cancelSource)
    };

    refreshStatements = async () => {
        await this.props.getStatements(this.props.filters, this.props.cancelSource)
    }
    render() {
        return (
            <React.Fragment>
                <Layout className="site-layout site-header-custom position-fixed">
                    <Header className="site-layout-background">
                        <div className="row flex-align-center h-100">
                            <div className="col-sm-7 col-12 col-md-7 col-lg-6">
                                <div className="row header-details-left mx-0 flex-align-center-between">
                                    <h5 className="mb-0 font-weight-bold">Cashflow</h5>
                                    <RangePicker
                                        onChange={this.dateChange}
                                        value={this.state.resetDate?[false,false]:[moment(this.state.startDate), moment(this.state.endDate)]}
                                        format={dateFormat}
                                    />
                                    <Tooltip title="Refresh">
                                        <Button onClick={() => this.refreshStatements()} className={"refresh-btn filter-green-btn w-auto d-flex align-items-center justify-content-between"}><ReloadOutlined /></Button>
                                    </Tooltip>
                                    <Button onClick={() => this.visibleFilter(true)}
                                        className="text-uppercase font-weight-bold filter-green-btn">Filter</Button>
                                </div>
                            </div>
                            <div className="col-sm-5 col-12 col-md-5 col-lg-6">
                                <div className="row mx-0 header-details-right flex-align-center justify-content-end">
                                    <div className="hdr-toggle-main">
                                        <Switch className="sl-shc-switch" onChange={this.props.handleToggleSwitch}
                                            checkedChildren={`CHART VIEW`} unCheckedChildren="LIST VIEW" />
                                        <PieChartFilled style={{ color: "#afb7be", fontSize: "17px" }}
                                            className="hdr-chart-icon" />
                                        <UnorderedListOutlined style={{ color: "#57b5a4", fontSize: "15px" }}
                                            className=" hdr-list-icon" />
                                    </div>
                                    <Search
                                        placeholder="input search text"
                                        onSearch={value => this.searchFilter(value)}
                                    />
                                    <Dropdown overlayClassName={'header-dropdown'} overlay={menu} trigger={['click']}>
                                        <Button className="bg-transparent border-0 shadow-none p-0 ant-dropdown-link"
                                            onClick={e => e.preventDefault()}>
                                            <img alt="user icon" src={Images.user_icon_header} className="img-fluid" />
                                        </Button>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </Header>
                </Layout>
                {/* <FilterModal visible={this.state.visible} onClose={() => this.visibleFilter(false)} /> */}
                <FilterModalNew visible={this.state.visible} onClose={() => this.visibleFilter(false)} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return { filters: state.filters, cancelSource: state.statements.cancelSource }
};
export default connect(mapStateToProps, { updateDateFilter, getStatements, updateSearchFilter })(CustomHeader);
