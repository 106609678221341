import React, { Component } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const COLORS = ['#bd4242', '#eb7070', '#19493e', '#e9fdf7', '#b4f2dc', '#57b5a4', '#bd4242', '#eb7070', '#19493e'];

class Charts extends Component {
    render() {
        const { graphData } = this.props
        return (
            <React.Fragment>
                {Object.keys(graphData).length && graphData['INCOME'] && <div className="col-md-12 col-sm-12">
                    <h4 className="graph-title">Income Summary</h4>
                    <ResponsiveContainer height={600} width="100%">
                        <PieChart onMouseEnter={this.onPieEnter}>
                            <Pie isAnimationActive={true} data={graphData['INCOME']}
                                labelLine={false}
                                innerRadius={150}
                                outerRadius={190}
                                fill="#8884d8"
                                paddingAngle={5}
                                dataKey="amount"
                                label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
                                    const RADIAN = Math.PI / 180;
                                    const radius = 10 + innerRadius + (outerRadius - innerRadius);
                                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                    const y = cy + radius * Math.sin(-midAngle * RADIAN);
                                    return (<text x={x} y={y} fontSize="12px" fill="#182027" fontWeight='600' textAnchor={x > cx ? "start" : "end"} dominantBaseline="middle" >
                                        <tspan>{graphData['INCOME'][index].category.name}</tspan> {`${(percent * 100).toFixed(1)}%`}
                                    </text>);
                                }}>
                                {graphData['INCOME'].map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>}
                <div className="mt-sm-5">
                    {Object.keys(graphData).length && graphData["EXPENSE"] && <div className="col-md-12 col-sm-12">
                        <h4 className="graph-title">Expense Summary</h4>
                        <ResponsiveContainer height={600} width="100%">
                            <PieChart onMouseEnter={this.onPieEnter}>
                                <Pie isAnimationActive={true} data={graphData['EXPENSE']}
                                    labelLine={false}
                                    innerRadius={150}
                                    outerRadius={190}
                                    fill="#8884d8"
                                    paddingAngle={5}
                                    dataKey="amount"
                                    label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
                                        const RADIAN = Math.PI / 180;
                                        const radius = 25 + innerRadius + (outerRadius - innerRadius);
                                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                        const y = cy + radius * Math.sin(-midAngle * RADIAN);
                                        return (<text x={x} y={y} fill="#182027" fontSize="12px" fontWeight='600' textAnchor={x > cx ? "start" : "end"} dominantBaseline="central" >
                                            <tspan>{graphData['EXPENSE'][index].category.name}</tspan> {`${(percent * 100).toFixed(1)}%`}
                                        </text>);
                                    }}>
                                    {graphData['EXPENSE'].map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
                                </Pie>Balance
                        </PieChart>
                        </ResponsiveContainer>
                    </div>}
                </div>
            </React.Fragment>
        )
    }
}
export default Charts
