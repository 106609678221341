import React, { Component } from 'react';
import { Button, Checkbox, Layout, Select, Form, Input, Popover, message } from "antd";
import { ChromePicker } from 'react-color';
import { addAccount } from "../Controller/API/PaymentAPI";
import { connect } from "react-redux";
import { getAccountsAction } from "../Redux/Actions/accountActions";

const { Content } = Layout;
const { Option } = Select;

class AddAccount extends Component {
    state = {
        color: ''
    };
    formRef = React.createRef();

    handleColor = (val) => {
        this.setState({ color: val.hex });
        this.formRef.current.setFieldsValue({ color: val.hex });
    };

    addAccount = (values) => {
        addAccount(values).then(response => {
            if (response.status === 201) {
                message.success('Account added successfully');
                this.props.getAccountsAction();
                this.formRef.current.resetFields();
            } else {
                message.error('Something went wrong')
            }
        }).catch(err => {
            message.error('Something went wrong')
        })
    };

    render() {
        return (
            <Layout className="site-layout site-layout-main ant-layout-main-second h-100 bg-white w-100 d-inline-block">
                <Content>
                    <div className="site-layout-background bg-white">
                        <div className="row heading-header-row">
                            <div className="col-12 col-sm-4 m-auto">
                                <h5 className="mb-0 font-weight-bold pl-4 position-relative">
                                    <span className="position-absolute tag-div tag-w-h-inc rounded-circle my-auto" />
                                    “Bank Account Title”
                                </h5>
                            </div>
                        </div>
                        <div className="row title-details-row">
                            <Form onFinish={this.addAccount} ref={this.formRef} className="w-100">
                                <div className="col-12 col-sm-4 m-auto">
                                    <div className="row modal-row-main">
                                        <div className="w-100">
                                            <div
                                                className="row mx-0 p-0 main-form-div bg-transparent rounded-0 shadow-none">
                                                <div className="w-100">
                                                    <div className="col-12">
                                                        <Form.Item
                                                            rules={[{ required: true, message: 'Title is required' }]}
                                                            label={'Title'}
                                                            name={'title'}
                                                            className="form-group position-relative small-size">
                                                            <Input placeholder="Title"
                                                                className="form-control font-weight-normal" />
                                                            {/*<label className="mb-0">Title*</label>*/}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-12">
                                                        <Form.Item label={'Starting Amount'} name={'starting_amount'}
                                                            className="form-group position-relative small-size">
                                                            <Input type="text" placeholder="Starting Amount"
                                                                className="form-control font-weight-normal" />
                                                            {/*<label className="mb-0">Starting Amount</label>*/}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-12">
                                                        <Form.Item label={'Currency'} name={'currency'}>
                                                            <Select placeholder="Currency">
                                                                <Option value="USD">USD</Option>
                                                                <Option value="INR">INR</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-12">
                                                        <Form.Item label={'Color'} name={'color'}
                                                            className="form-group position-relative small-size">
                                                            <Popover trigger={'click'}
                                                                content={<ChromePicker onChange={this.handleColor}
                                                                    color={this.state.color} />}>
                                                                <Input value={this.state.color}
                                                                    placeholder={'Bank Account Color'} />
                                                            </Popover>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <Checkbox>Default Bank Account</Checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="ant-modal-footer footer-custom bg-white">
                                    <Button className="shadow-none">CANCEL</Button>
                                    <Button htmlType="submit" className="shadow-none"
                                        type="primary">Add Bank
                                        Account</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accounts: state.accounts
    }
};
export default connect(mapStateToProps, { getAccountsAction })(AddAccount);