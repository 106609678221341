import React from 'react';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css'
import '../src/assets/css/style.css'
import '../src/assets/css/responsive.css'

import 'bootstrap/dist/js/bootstrap';
import 'jquery'
import 'popper.js'
import {Router} from "react-router-dom";
import {history} from "./Controller/history";
import Login from "./Components/Login";
import Main from "./Components/Main";
import {PublicRoute} from "./Controller/PublicPath";
import PrivateRoute from "./Controller/PrivatePath";
import Logout from "./Components/Logout";
import {routes} from "./Controller/routes";

function App() {
  return (
    <div className="App text-left bg-white">
      <Router history={history}>
        <div className="h-100">
          <PublicRoute exact restricted={true} path={routes.login} component={Login}/>
          <PrivateRoute path={routes.dashboard.self} component={Main}/>
          <PrivateRoute exact path={routes.logout} component={Logout}/>
        </div>
      </Router>
    </div>

  );
}

export default App;
