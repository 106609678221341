import React, {Component} from 'react';
import {logout} from "../Controller/localStorageHandler";
import {history} from "../Controller/history";
import {routes} from "../Controller/routes";

class Logout extends Component {
    componentDidMount() {
        logout();
        history.push(routes.login)
    }

    render() {
        return (
            <React.Fragment/>
        );
    }
}

export default Logout;