import React, {Component} from 'react';
import {message, Modal, Table} from "antd";
import {plaidSyncTransactionStatement, plaidTransactionStatement} from "../../Controller/API/PaymentAPI";
import {connect} from "react-redux";
import {getStatements} from "../../Redux/Actions/statementAction";
import {getAccountsAction} from "../../Redux/Actions/accountActions";

class GetTransaction extends Component {
    state = {
        transactions: [],
        syncBtnLoading: false,
        tableLoading: true
    }
    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
    ];

    componentDidMount() {
        plaidTransactionStatement({access_token: this.props.access_token})
            .then(response => {
                this.setState({transactions: response.data.transactions, tableLoading: false})
            })
            .catch(err => {
                message.info("Something when wrong, Please try again after again login")
            })
    }

    handleSyncTransaction = () => {
        this.setState({syncBtnLoading: true})
        plaidSyncTransactionStatement({access_token: this.props.access_token})
            .then(response => {
                this.setState({syncBtnLoading: false})
                const transactions = response.data.transactions
                if (transactions) {
                    message.success(`${transactions} transaction successfully synchronized`)
                    this.props.getStatements({}, this.props.cancelSource);
                } else {
                    message.info(`No transaction synchronized`)
                }
                this.props.getAccountsAction();
                this.props.onClose()


            })
            .catch(() => {
                this.setState({syncBtnLoading: false})
                message.info("Something when wrong, Please try again after again login")
            })
    }

    render() {
        const {visible} = this.props;

        const {transactions, syncBtnLoading, tableLoading} = this.state
        return (
            <Modal maskClosable={false} confirmLoading={syncBtnLoading} width="40%" title="All Transaction"
                   visible={visible}
                   onOk={this.handleSyncTransaction}
                   onCancel={this.props.onClose} okText={"Sync all transactions"}>
                <div className="listing-table table-responsive">
                    <Table loading={tableLoading}
                           className="responsive-table table table-hover table-custom  detail-table"
                           dataSource={transactions}
                           rowKey={record => record.transaction_id}
                           columns={this.columns} pagination={false}/>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cancelSource: state.statements.cancelSource
    }
};
export default connect(mapStateToProps, {
    getStatements,
    getAccountsAction
})
(GetTransaction);
