import React, {Component} from 'react';
import {Input, message, Modal} from "antd";
import {addEntity} from "../../Controller/API/PaymentAPI";
import {addEntityAction, getEntityAction} from "../../Redux/Actions/entityActions";
import {connect} from "react-redux";

class AddEntity extends Component {
    state = {
        name: ''
    };
    addEntity = () => {
        const {name} = this.state;
        if (name === "") {
            message.error('Entity Name is required')
        }
        let data = {
            name,
        };
        addEntity(data).then(response => {
            if (response.status === 201) {
                message.success('Entity Added Successfully!');
                this.props.getEntityAction();
                this.props.onClose();
                this.setState({name: ''})
            }
        }).catch(err => {
            message.error('Error while adding Entity!')
        })
    };

    render() {
        return (
            <div>
                <Modal
                    title="Add Entity"
                    destroyOnClose={true}
                    visible={this.props.visible}
                    onOk={this.addEntity}
                    onCancel={this.props.onClose}
                    width="26%"
                    cancelText="cancel"
                    okText="Add Entity"
                >
                    <div
                        className="row mx-0 p-0 main-form-div bg-transparent rounded-0 shadow-none">
                        <div className="col-12">
                            <form>
                                <div className="form-group position-relative small-size">
                                    <Input onChange={e => this.setState({name: e.target.value})} type="text"
                                           placeholder="Entity Name"
                                           className="form-control font-weight-normal"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        entites: state.entites
    }
};
export default connect(mapStateToProps, {getEntityAction})(AddEntity);