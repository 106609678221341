import React, { Component } from 'react';
import { Button, Checkbox, Col, Dropdown, Layout, Menu, Row } from 'antd';
import { Image as Images } from "./Images";
import { Link, NavLink } from "react-router-dom";
import { reverse } from "named-urls/src";
import { routes } from "../Controller/routes";
import { methods, payment } from "../Controller/Global";
import { getAccountsAction } from "../Redux/Actions/accountActions";
import { getEntityAction } from "../Redux/Actions/entityActions";
import { getAllFilters, updateAccountFilter, updateEntityFilter } from "../Redux/Actions/filterAction";
import { getStatements } from "../Redux/Actions/statementAction";
import { getTransAction } from "../Redux/Actions/transAccountAction";
import PlaidPopup from "./Plaid/PlaidPopup";
import AccountTransactionPopup from "./Plaid/AccountTransactionPopup";
import { connect } from "react-redux";

const { Sider } = Layout;
const { SubMenu } = Menu;


class SideBar extends Component {
    state = {
        collapsed: false,
        smallSidebar: false,
        checkedAcc: [],
        checkedEnt: [],
        transactionPopup: false,
        account: null
    };
    menu = (
        <Menu>
            <Menu.Item>
                <Link to={reverse(routes.dashboard.payment.method, {
                    type: payment.income,
                    method: methods.add
                })}>Income</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to={reverse(routes.dashboard.payment.method, {
                    type: payment.expense,
                    method: methods.add
                })}>Expense</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to={"/dashboard/account-title/add-account/"}>Bank Account</Link>
            </Menu.Item>
        </Menu>
    )

    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    componentDidMount() {
        let width = window.innerWidth;
        if (width <= 1200) {
            this.setState({ smallSidebar: true })
        }
        this.props.getAccountsAction();
        this.props.getEntityAction();
        this.props.getAllFilters();
        this.props.getTransAction();
    }

    onAccountChange = async (checkedValues) => {
        this.setState({ checkedAcc: checkedValues });
        await this.props.updateAccountFilter(checkedValues.length == 0 ? null : checkedValues.join(','));
        await this.props.getStatements(this.props.filters, this.props.cancelSource)
    };

    onEntityChange = async (checkedValues) => {
        this.setState({ checkedEnt: checkedValues });
        await this.props.updateEntityFilter(checkedValues.length == 0 ? null : checkedValues.join(','));
        await this.props.getStatements(this.props.filters, this.props.cancelSource)
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filters !== this.props.filters) {
            if (this.props.filters.account_list === null && this.props.filters.entity_list === null) {
                this.setState({ checkedAcc: [], checkedEnt: [] })
            }
        }
    }

    handleRadioTransaction = (e, item) => {
        e.stopPropagation()
        if (item.is_connected)
            this.setState({ transactionPopup: true, account: item.id })
    }
    handleTransactionPopup = (status) => {
        this.setState({ transactionPopup: status, account: null })
    }
    handleBankSelectAll = (val) => {
        if (val.target.checked) {
            const checkedValues = this.props.accounts && this.props.accounts.map((item) => {
                return item.id
            })
            this.setState({ checkedAcc: checkedValues });
            this.onAccountChange(checkedValues)
        }
        else {
            this.setState({ checkedAcc: [] });
            this.onAccountChange([])
        }

    }

    render() {
        const { smallSidebar } = this.state;
        return (
            <React.Fragment>
                <Sider collapsible collapsed={this.state.collapsed}
                    className={`main-sidebar-div h-100 position-fixed ${smallSidebar ? 'small-sidebar' : ''}`}
                    onCollapse={this.onCollapse}>
                    <div className="logo flex-align-center-center">
                        <Link to="/dashboard/" className="d-inline-block">
                            <img className="img-fluid" src={Images.logo_sidebar_small} alt="small-logo" />
                        </Link>
                    </div>
                    <div className="add-btn-div px-3 py-4">
                        <Dropdown overlay={this.menu} trigger={['click']}>
                            <Button onClick={(e) => e.preventDefault()}
                                className="text-uppercase border-0 shadow-none p-0 flex-align-center bg-transparent w-100 text-left">
                                <span className="plus-tag flex-align-center-center bg-white">
                                    <img className="img-fluid" alt="plus icon" src={Images.plus_green} />
                                </span>
                                Add
                            </Button>
                        </Dropdown>
                    </div>
                    <div className="menu-div-main py-3">
                        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                            <Menu.Item key="1" className="flex-align-center m-0 px-0">
                                <NavLink to="/dashboard/" className="flex-align-center w-100 position-relative">
                                    <span className="checkbox-span-tag position-absolute my-auto" />
                                    <span className="text">Cashflow</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="2" className="flex-align-center m-0 px-0">
                                <a href="#" className="flex-align-center w-100 position-relative">
                                    <span className="checkbox-span-tag position-absolute my-auto" />
                                    <span className="text">Reports</span>
                                </a>
                            </Menu.Item>
                            <Menu.Item key="3" className="flex-align-center m-0 px-0">
                                <a href="#" className="flex-align-center w-100 position-relative">
                                    <span className="checkbox-span-tag position-absolute my-auto" />
                                    <span className="text">Companies</span>
                                </a>
                            </Menu.Item>
                        </Menu>
                        <PlaidPopup />
                    </div>
                    <div className="sub-menu-div-inner py-3">
                        <Menu theme="dark" defaultSelectedKeys={['sub1']} mode="inline">
                            <SubMenu
                                key="sub1"
                                title={
                                    <span>
                                        <span>Bank Accounts</span>
                                    </span>
                                }
                            >
                                 <Checkbox className="custom-checkbox-select-all" onClick={(val) => this.handleBankSelectAll(val)}>Select All</Checkbox>
                                <Checkbox.Group
                                    value={this.state.checkedAcc}
                                    style={{ width: '100%' }}
                                    onChange={this.onAccountChange}>
                                    <Row className="linked-banks">
                                        {this.props.accounts && this.props.accounts.map(item => (
                                            <Col className="linked-banks-div" key={item.id} span={24}>
                                                <Checkbox value={item.id}
                                                    style={{ color: "green", width: 'auto', float: 'left' }} />
                                                <span style={{
                                                    color: item.is_connected ? "green" : "red",
                                                    fontSize: 13,
                                                    cursor: "pointer"
                                                }}
                                                    onClick={(e) => this.handleRadioTransaction(e, item)}>
                                                    {item.title}
                                                </span>
                                            </Col>
                                        ))}
                                    </Row>
                                </Checkbox.Group>
                            </SubMenu>
                            <SubMenu
                                key="sub2"
                                title={
                                    <span>
                                        <span>Entities</span>
                                    </span>
                                }
                            >
                                <Checkbox.Group
                                    value={this.state.checkedEnt}
                                    style={{ width: '100%' }}
                                    onChange={this.onEntityChange}>
                                    <Row>
                                        {this.props.entities && this.props.entities.map(item => (
                                            <Col key={item.id} span={24}>
                                                <Checkbox value={item.id}>
                                                    <span style={{ backgroundColor: '#BB7EF5' }}
                                                        className="tag-apple-type position-absolute my-auto" />
                                                    {item.name}
                                                </Checkbox>
                                            </Col>
                                        ))}
                                    </Row>
                                </Checkbox.Group>
                            </SubMenu>
                        </Menu>
                    </div>
                </Sider>
                {this.state.transactionPopup ?
                    <AccountTransactionPopup account={this.state.account} visible={this.state.transactionPopup}
                        onClose={() => this.handleTransactionPopup(false)} />
                    : ""}
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        accounts: state.accounts,
        entities: state.entities,
        filters: state.filters,
        cancelSource: state.statements.cancelSource

    }
};
export default connect(mapStateToProps, {
    getAccountsAction,
    getEntityAction,
    getTransAction,
    updateAccountFilter,
    updateEntityFilter,
    getAllFilters,
    getStatements
}
)(SideBar);
