import {getAPIUrl} from "../Global";
import {Get, Patch, Post} from "../headerIntercepter";

export function getCategory(type) {
    const url = getAPIUrl('category.self');
    return Get(url, type)
}

export function addCategory(data) {
    const url = getAPIUrl('category.self');
    return Post(url, data)
}

export function getAccounts(data = {}) {
    const url = getAPIUrl('account.self');
    return Get(url, data)
}

export function addAccount(data) {
    const url = getAPIUrl('account.self');
    return Post(url, data)
}

export function getEntity(data = {}) {
    const url = getAPIUrl('entity.self');
    return Get(url, data)
}

export function addEntity(data) {
    const url = getAPIUrl('entity.self');
    return Post(url, data)
}

export function getTransAccount(data = {}) {
    const url = getAPIUrl('transAccount.self');
    return Get(url, data)
}

export function getTransResponsibility(data = {}) {
    const url = getAPIUrl('transResponsibility.self');
    return Get(url, data)
}

export function addTransAccount(data) {
    const url = getAPIUrl('transAccount.self');
    return Post(url, data)
}

export function addStatement(data) {
    const url = getAPIUrl('statement.self');
    return Post(url, data)
}

export function updateStatement(id, data) {
    const url = getAPIUrl('statement.id', {
        id
    });
    return Patch(url, data)
}

export function getStatement(data = {}, cancelToken = null) {
    const url = getAPIUrl('statement.self');
    return Get(url, data, cancelToken)
}

export function getStatementById(id) {
    const url = getAPIUrl('statement.id', {
        id
    });
    return Get(url, {})
}


export function plaidAccessTokenStatement(data) {
    const url = getAPIUrl('plaid.access_token');
    return Post(url, data)
}

export function plaidLinkTokenStatement(data) {
    const url = getAPIUrl('plaid.create_link_token');
    return Post(url, data)
}

export function plaidTransactionStatement(data) {
    const url = getAPIUrl('plaid.transactions');
    return Post(url, data)
}

export function plaidSyncTransactionStatement(data) {
    const url = getAPIUrl('plaid.sync_plaid_statement');
    return Post(url, data)
}

export function updateAllStatements(data) {
    const url = getAPIUrl('updateAllStatement');
    return Post(url, data)
}

export function syncAccountTransaction(data) {
    const url = getAPIUrl('syncAccountTransaction');
    return Post(url, data)
}
