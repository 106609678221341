import React, {Component} from 'react';
import {methods} from "../../Controller/Global";
import IncomeTitle from "../IncomeTitle";
import AddIncome from "../AddIncome";
import {history} from "../../Controller/history";
import {routes} from "../../Controller/routes";

class PaymentMethod extends Component {

    setComponent = () => {
        const {match} = this.props;
        if (match.params.method === methods.add || (match.params.method === methods.edit && match.params.id))
            return <AddIncome {...this.props}/>;
        else if (match.params.method === methods.view && match.params.id)
            return <IncomeTitle {...this.props}/>;
        else
            return history.push(routes.dashboard.self);
    };

    render() {
        return (
            <div>
                {this.setComponent()}
            </div>
        );
    }
}

export default PaymentMethod;