import React, {Component} from 'react';
import {Input, message, Modal} from 'antd';
import {addCategory} from "../../Controller/API/PaymentAPI";

class AddCategory extends Component {
    state = {
        name: ''
    };
    addCategory = () => {
        const {name} = this.state;
        if (name === "") {
            message.error('Category Name is required')
        }
        let data = {
            name,
            stats_type: this.props.type
        };
        addCategory(data).then(response => {
            if (response.status === 201) {
                message.success('Category Added Successfully!');
                this.props.onSuccess();
                this.setState({name: ''})
            }
        }).catch(err => {
            message.error('Category Add Failed!')
        })
    };

    render() {
        return (
            <Modal
                title="Add Category"
                destroyOnClose={true}
                visible={this.props.visible}
                onOk={this.addCategory}
                onCancel={this.props.onClose}
                width="26%"
                cancelText="cancel"
                okText="Add Category"
            >
                <div
                    className="row mx-0 p-0 main-form-div bg-transparent rounded-0 shadow-none">
                    <div className="col-12">
                        <form>
                            <div className="form-group position-relative small-size">
                                <Input onChange={e => this.setState({name: e.target.value})} type="text"
                                       placeholder="Category Name"
                                       className="form-control font-weight-normal"/>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default AddCategory;