import {Button, Select, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";

const {Option} = Select;

const getDropdownSearchProps = (type, onFocus, onSearch, result, onChange, onApply) => ({
    filterDropdown: () => (
        <div style={{padding: 8,width:200}}>
            <Select
                placeholder={type}
                showSearch
                style={{width:'100%'}}
                optionFilterProp="children"
                onFocus={(e) => onFocus()}
                onSearch={(value) => onSearch({search: value})}
                onChange={(value) => onChange(value, type)}
            >
                {result.map(item => (
                    <Option key={item.id}
                            value={item.id}>{item.name}</Option>
                ))}
            </Select>
            <br/>
            <Space>
                <Button
                    type="primary"
                    onClick={() => onApply(type)}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{width: 183,marginTop:7,display:'flex',alignItems:'center',justifyContent:'center'}}
                >
                    Apply
                </Button>
            </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
});

export default getDropdownSearchProps
