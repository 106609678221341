import {
    GET_ALL_FILTER,
    RESET_FILTERS,
    UPDATE_ACCOUNT_FILTER,
    UPDATE_ASSET_TYPE,
    UPDATE_CATEGORY_FILTER,
    UPDATE_DATE_FILTER,
    UPDATE_ENTITY_FILTER,
    UPDATE_EXPENSE_CATEGORY_FILTER,
    UPDATE_INCOME_CATEGORY_FILTER,
    UPDATE_SEARCH_FILTER,
    UPDATE_STATS_TYPE,
    UPDATE_INITIAL_AMOUNT_CHECKED,
    UPDATE_LIQUIDITY_TYPE,
    UPDATE_TRANSACTION_ACCOUNT_LIST,
    UPDATE_TRANSACTION_RESPONSIBILITY_LIST
} from "./actionType";

export const getAllFilters = () => {
    return async dispatch => {
        dispatch({type: GET_ALL_FILTER, payload: ''})
    }
};
export const updateAccountFilter = (data) => {
    return async dispatch => {
        dispatch({type: UPDATE_ACCOUNT_FILTER, payload: data})
    }
};
export const updateEntityFilter = (data) => {
    return async dispatch => {
        dispatch({type: UPDATE_ENTITY_FILTER, payload: data})
    }
};
export const updateDateFilter = (data) => {
    return async dispatch => {
        dispatch({type: UPDATE_DATE_FILTER, payload: data})
    }
};
export const updateSearchFilter = (data) => {
    return async dispatch => {
        dispatch({type: UPDATE_SEARCH_FILTER, payload: data})
    }
};

export const updateIncomeCategoryFilter = (data) => {
    return async dispatch => {
        dispatch({type: UPDATE_INCOME_CATEGORY_FILTER, payload: data})
    }
};

export const updateExpenseCategoryFilter = (data) => {
    return async dispatch => {
        dispatch({type: UPDATE_EXPENSE_CATEGORY_FILTER, payload: data})
    }
};

export const updateTransactionAccountFilter = (data) => {
    return async dispatch => {
        dispatch({type: UPDATE_TRANSACTION_ACCOUNT_LIST, payload: data})
    }
};
export const updateTransactionReponsibilityFilter = (data) => {
    return async dispatch => {
        dispatch({type: UPDATE_TRANSACTION_RESPONSIBILITY_LIST, payload: data})
    }
};
export const updateStatsType = (data) => {
    return async dispatch => {
        dispatch({type: UPDATE_STATS_TYPE, payload: data})
    }
};
export const updateAssetType = (data) => {
    return async dispatch => {
        dispatch({type: UPDATE_ASSET_TYPE, payload: data})
    }
};
export const updateLiquidityType = (data) => {
    return async dispatch => {
        dispatch({type: UPDATE_LIQUIDITY_TYPE, payload: data})
    }
};
export const updateInitialAmountChecked = (data) => {
    return async dispatch => {
        dispatch({type: UPDATE_INITIAL_AMOUNT_CHECKED, payload: data})
    }
};
export const resetFilters = () => {
    return async dispatch => {
        dispatch({type: RESET_FILTERS, payload: ""})
    }
};
