import {addAccount, getAccounts} from "../../Controller/API/PaymentAPI";
import {ADD_ACCOUNT, GET_ACCOUNT} from "./actionType";


export const getAccountsAction = () => {
    return async dispatch => {
        getAccounts().then(response => {
            dispatch({
                type: GET_ACCOUNT,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_ACCOUNT,
                payload: []
            })
        })
    }
};

export const addAccountsAction = (data) => {
    return async dispatch => {
        addAccount(data).then(response => {
            dispatch({
                type: ADD_ACCOUNT,
                payload: response.data
            })
        }).catch(err => {

        })
    }
};