import {addTransAccount, getTransAccount} from "../../Controller/API/PaymentAPI";
import {ADD_TRANS_ACCOUNT, GET_TRANS_ACCOUNT} from "./actionType";


export const getTransAction = () => {
    return async dispatch => {
        getTransAccount().then(response => {
            dispatch({
                type: GET_TRANS_ACCOUNT,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_TRANS_ACCOUNT,
                payload: []
            })
        })
    }
};

export const addTransAction = (data) => {
    return async dispatch => {
        addTransAccount(data).then(response => {
            dispatch({
                type: ADD_TRANS_ACCOUNT,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: ADD_TRANS_ACCOUNT,
                payload: []
            })
        })
    }
};