import {combineReducers} from 'redux'
import statementReducer from "./statementReducer";
import accountReducer from "./accountReducer";
import entityReducer from "./entityReducer";
import transAccountReducer from "./transAccountReducer";
import filterReducer from "./filterReducer";

export const rootReducer = combineReducers({
    statements: statementReducer,
    accounts: accountReducer,
    entities: entityReducer,
    filters: filterReducer,
    transAccount:transAccountReducer
});