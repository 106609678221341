import React, {Component} from 'react';
import SideBar from "./SideBar";
import {Route, Switch} from "react-router-dom";
import Dashboard from "./Dashboard";
import AccountTitle from "./AccountTitle";
import AddAccount from "./AddAccount";
import IncomeTitle from "./IncomeTitle";
import AddIncome from "./AddIncome";
import EditIncome from "./EditIncome";
import {routes} from "../Controller/routes";
import PaymentMethod from "./Payment/PaymentMethod";


class Main extends Component {
    render() {
        return (
            <React.Fragment>
                <SideBar/>
                <Switch>
                    <React.Fragment>
                        <Route exact path={routes.dashboard.self} component={Dashboard}/>
                        <Route exact path="/dashboard/account-title/" component={AccountTitle}/>
                        <Route exact path={routes.dashboard.payment.method} component={PaymentMethod}/>
                        <Route exact path="/dashboard/account-title/add-account/" component={AddAccount}/>
                        <Route exact path="/dashboard/income/" component={IncomeTitle}/>
                        <Route exact path="/dashboard/income/add-income/" component={AddIncome}/>
                        <Route exact path="/dashboard/income/edit-income/" component={EditIncome}/>
                    </React.Fragment>
                </Switch>

            </React.Fragment>
        );
    }
}

export default Main;