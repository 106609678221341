import React, { Component } from 'react';
import { Layout } from "antd";
import CashFlowTable from "./CashFlowTable";
import CustomHeader from "./CustomHeader";
import CashFlowCharts from './ChartView';

const { Content } = Layout;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleSwitch: true,
        }
        this.switch = React.createRef();
    }
    handleToggleSwitch = () => {
        this.setState({
            toggleSwitch: !this.state.toggleSwitch
        })
    }

    render() {
        const { toggleSwitch } = this.state
        return (
            <React.Fragment>
                <CustomHeader refs={this.switch} handleToggleSwitch={this.handleToggleSwitch} />
                <Layout className="site-layout site-layout-main bg-white w-100 d-inline-block">
                    <Content>
                        <div className="site-layout-background bg-white">
                            <div className="row">
                                <div className="col-12">
                                    {toggleSwitch ? <CashFlowTable />
                                        : <CashFlowCharts />}
                                </div>
                            </div>
                        </div>
                    </Content>
                </Layout>
            </React.Fragment>
        );
    }
}

export default Dashboard;