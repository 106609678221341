import React, {Component} from 'react';
import {Button, Checkbox, DatePicker, Divider, Form, Input, Layout, message, Select, Spin} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import AddCategory from "./modal/AddCategory";
import {methods, payment} from "../Controller/Global";
import {
    addStatement,
    getAccounts,
    getCategory,
    getEntity,
    getStatementById,
    getTransAccount,
    getTransResponsibility,
    updateStatement
} from "../Controller/API/PaymentAPI";
import moment from "moment";
import {reverse} from "named-urls/src";
import {history} from "../Controller/history";
import {routes} from "../Controller/routes";
import AddEntity from "./modal/AddEntity";
import {connect} from "react-redux";
import AddTransAccount from "./modal/AddTransAccount";
import {getStatements} from "../Redux/Actions/statementAction";

const {Content} = Layout;
const {Option} = Select;
const {TextArea} = Input;

class AddIncome extends Component {

    state = {
        items: ['Category', 'Category'],
        name: '',
        visible: false,
        visibleEntity: false,
        visibleTrans: false,
        category: [],
        account: [],
        entity: [],
        transAccount: [],
        transaction_responsibility: []
    };
    formRef = React.createRef();

    categoryVisible = (visible) => {
        this.setState({
            visible: visible,
        })
    };
    entityVisible = (visible) => {
        this.setState({
            visibleEntity: visible,
        })
    };

    transVisible = (visible) => {
        this.setState({
            visibleTrans: visible,
        })
    };

    componentDidMount() {
        let type = this.props.match.params.type;
        this.fetchCategory(type);
        this.fetchTransResponsibility()
        // this.fetchAccount();
        // this.fetchEntity();
        if (this.props.match.params.method === methods.edit && this.props.match.params.id) {
            getStatementById(this.props.match.params.id).then(response => {
                this.formRef.current.setFieldsValue({
                    ...response.data,
                    date: moment(response.data.date),
                    category: response.data.category.id,
                    account: response.data.account.id,
                    entity: response.data.entity.id,
                    active_entry: response.data.active_entry,
                    transaction_account: response.data.transaction_account && response.data.transaction_account.id || undefined,
                    transaction_responsibility: response.data.transaction_responsibility && response.data.transaction_responsibility.id || undefined
                })
            }).catch(err => {
                console.log(err);
                message.error('Error while fetching statement')
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.props.match.params.method === methods.add && this.formRef.current.resetFields();
            let type = this.props.match.params.type;
            this.fetchCategory(type)
        }
    }

    fetchAccount = () => {
        getAccounts().then(response => {
            this.setState({account: response.data})
        }).catch(err => {
            message.error('Something went wrong')
        })
    };
    fetchCategory = (type) => {
        let data = {stats_type: type.toUpperCase()};
        getCategory(data).then(response => {
            this.setState({category: response.data})
        }).catch(err => {
            message.error('Something went wrong')
        })
    };
    fetchEntity = () => {
        getEntity().then(response => {
            this.setState({entity: response.data})
        }).catch(err => {
            message.error('Something went wrong')
        })
    };
    fetchTransAccount = () => {
        getTransAccount().then(response => {
            this.setState({transAccount: response.data})
        }).catch(err => {
            message.error('Something went wrong')
        })
    };

    fetchTransResponsibility = () => {
        getTransResponsibility().then(response => {
            this.setState({transResponsibility: response.data})
        }).catch(err => {
            message.error('Something went wrong')
        })
    };

    onSubmit = async (values) => {
        values.date = moment(values.date).format('YYYY-MM-DD');
        values.stats_type = this.props.match.params.type.toUpperCase();
        if (this.props.match.params.method === methods.add) {
            addStatement(values).then(response => {
                if (response.status === 201) {
                    message.success(`${this.props.match.params.type} Added Successfully!`);
                    this.formRef.current.resetFields();
                }
                this.props.getStatements(this.props.filters);
                history.push("/dashboard/")

            }).catch(err => {
                message.error('Error while Adding!');
                console.log(err, 'err add')
            })
        }
        if (this.props.match.params.method === methods.edit) {
            updateStatement(this.props.match.params.id, values).then(response => {
                if (response.status === 200) {
                    message.success(`${this.props.match.params.type} Updated Successfully!`);
                    history.push(reverse(routes.dashboard.payment.method, {
                        type: this.props.match.params.type,
                        method: methods.view,
                        id: this.props.match.params.id
                    }))
                }
                this.props.getStatements(this.props.filters);
                history.push("/dashboard/")
            }).catch(err => {
                message.error('Error while Update!');
                console.log(err, 'err add')
            })
        }
    };

    render() {
        const {category} = this.state;
        const {match} = this.props;
        const method = match.params.method === methods.add ? "Add" : "Update";
        const type = match.params.type === payment.income ? "Income" : "Expense";
        return (
            <Layout className="site-layout site-layout-main ant-layout-main-second bg-white w-100 d-inline-block">
                <Content>
                    <div className="site-layout-background bg-white">
                        <div className="row heading-header-row">
                            <div className="col-12 col-sm-6 m-auto">
                                <h5 className="mb-0 font-weight-bold">
                                    {`${method} ${type}`}
                                </h5>
                            </div>
                        </div>
                        <Form onFinish={this.onSubmit} ref={this.formRef}>
                            <div className="row title-details-row">
                                <div className="col-12 col-sm-8 m-auto">
                                    <div className="row modal-row-main">
                                        <div className="w-100">
                                            <div
                                                className="row mx-0 p-0 main-form-div bg-transparent rounded-0 shadow-none">
                                                <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                                                    <Form.Item label={'Entity'} name={'entity'} rules={[{
                                                        required: true,
                                                        message: 'Entity is required'
                                                    }]}>
                                                        <Select placeholder="Entity"
                                                                showSearch
                                                                optionFilterProp="children"
                                                                notFoundContent={!this.props.entities ?
                                                                    <Spin size="small"/> : null}
                                                                dropdownRender={menu => (
                                                                    <div>
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            flexWrap: 'nowrap',
                                                                            padding: 8
                                                                        }}>
                                                                            <Button
                                                                                onClick={() => this.entityVisible(true)}
                                                                                className="border-0 shadow-none edit-item-btn rounded-0 d-inline-block">
                                                                                <PlusOutlined/> Add Entity
                                                                            </Button>
                                                                        </div>
                                                                        <Divider style={{margin: '4px 0'}}/>
                                                                        {menu}
                                                                    </div>
                                                                )}
                                                        >
                                                            {this.props.entities && this.props.entities.map(item => (
                                                                <Option key={item.id}
                                                                        value={item.id}>{item.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                                                    <Form.Item
                                                        label={'Category'}
                                                        rules={[{required: true, message: 'Category is required'}]}
                                                        name={'category'}>
                                                        <Select
                                                            placeholder="Category"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            dropdownRender={menu => (
                                                                <div>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexWrap: 'nowrap',
                                                                        padding: 8
                                                                    }}>
                                                                        <Button
                                                                            onClick={() => this.categoryVisible(true)}
                                                                            className="border-0 shadow-none edit-item-btn rounded-0 d-inline-block">
                                                                            <PlusOutlined/> Add Category
                                                                        </Button>
                                                                    </div>
                                                                    <Divider style={{margin: '4px 0'}}/>
                                                                    {menu}
                                                                </div>
                                                            )}
                                                        >
                                                            {category.map(item => (
                                                                <Option key={item.id}
                                                                        value={item.id}>{item.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                                                    <Form.Item label={'Bank Account'} name={'account'} rules={[{
                                                        required: true,
                                                        message: 'Bank Account is required'
                                                    }]}>
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            notFoundContent={!this.props.accounts ?
                                                                <Spin size="small"/> : null}
                                                            placeholder="Bank Account">
                                                            {this.props.accounts && this.props.accounts.map(item => (
                                                                <Option key={item.id}
                                                                        value={item.id}>{item.title}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                                                    <Form.Item label={'Transaction Reponsibility'}
                                                               name={'transaction_responsibility'} rules={[{
                                                        required: true,
                                                        message: 'Transaction Reponsibility is required'
                                                    }]}>
                                                        <Select placeholder="Transaction Reponsibility"
                                                                showSearch
                                                                optionFilterProp="children"
                                                                notFoundContent={!this.state.transResponsibility ?
                                                                    <Spin size="small"/> : null}
                                                                dropdownRender={menu => (
                                                                    <div>
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            flexWrap: 'nowrap',
                                                                            padding: 8
                                                                        }}>
                                                                            <Button
                                                                                onClick={() => this.entityVisible(true)}
                                                                                className="border-0 shadow-none edit-item-btn rounded-0 d-inline-block">
                                                                                <PlusOutlined/> Add Transaction
                                                                                Reponsibility
                                                                            </Button>
                                                                        </div>
                                                                        <Divider style={{margin: '4px 0'}}/>
                                                                        {menu}
                                                                    </div>
                                                                )}
                                                        >
                                                            {this.state.transResponsibility && this.state.transResponsibility.map(item => (
                                                                <Option key={item.id}
                                                                        value={item.id}>{item.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12">
                                                    <Form.Item label={'Transaction Account'}
                                                               name={'transaction_account'} rules={[{
                                                        required: true,
                                                        message: 'Transaction is required'
                                                    }]}>
                                                        <Select placeholder="Transaction Account"
                                                                showSearch
                                                                optionFilterProp="children"
                                                                notFoundContent={!this.props.transAccount ?
                                                                    <Spin size="small"/> : null}
                                                                dropdownRender={menu => (
                                                                    <div>
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            flexWrap: 'nowrap',
                                                                            padding: 8
                                                                        }}>
                                                                            <Button
                                                                                onClick={() => this.transVisible(true)}
                                                                                className="border-0 shadow-none edit-item-btn rounded-0 d-inline-block">
                                                                                <PlusOutlined/> Add Transaction Account
                                                                            </Button>
                                                                        </div>
                                                                        <Divider style={{margin: '4px 0'}}/>
                                                                        {menu}
                                                                    </div>
                                                                )}
                                                        >
                                                            {this.props.transAccount && this.props.transAccount.map(item => (
                                                                <Option key={item.id}
                                                                        value={item.id}>{item.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-12">
                                                    <Form.Item label={'Description'} name={'description'}
                                                               className="form-group">
                                                        <TextArea rows={4} placeholder="Description"/>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12" style={{borderTop: '1px solid #EDF1F4'}}>
                                    <div className="w-100">
                                        <div
                                            className="row mx-0 main-form-div px-0 bg-transparent rounded-0 shadow-none">
                                            <div className="col-sm-8 col-12 m-auto">
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-md-6">
                                                        <Form.Item
                                                            label={'Amount'}
                                                            rules={[{required: true, message: 'Amount is required'}]}
                                                            name={'amount'}
                                                            className="form-group position-relative small-size">
                                                            <Input placeholder={'Amount'}
                                                                   className="form-control font-weight-normal"/>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6">
                                                        <Form.Item label={'Date'} name={'date'} rules={[{
                                                            required: true,
                                                            message: 'Date is required'
                                                        }]} className="form-group position-relative small-size">
                                                            <DatePicker format={'MM/DD/YYYY'}
                                                                        className="form-control small-size font-weight-normal"/>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 modal-row-main">
                                                        <Form.Item label={'Repeat'}>
                                                            <Select placeholder="Repeat">
                                                                <Option value="jack">Never</Option>
                                                                <Option value="lucy">Never</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6">
                                                        <div className="form-group position-relative small-size">
                                                            <Form.Item label={'Memo'} name={'memo'}><Input
                                                                placeholder={'Memo'}
                                                                className="form-control font-weight-normal"/></Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mt-1 mb-5">
                                                        <Form.Item valuePropName="checked" name={'active_entry'}>
                                                            <Checkbox>Active Entry</Checkbox>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="ant-modal-footer footer-custom bg-white">
                                    <Button className="shadow-none"
                                            onClick={() => history.push("/dashboard/")}>CANCEL</Button>
                                    <Button className="shadow-none"
                                            htmlType="submit"
                                            type="primary">{`${method} ${type}`}</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Content>
                <AddCategory type={type.toUpperCase()} visible={this.state.visible} onSuccess={async () => {
                    this.categoryVisible(false);
                    await this.fetchCategory(type)
                }} onClose={() => this.categoryVisible(false)}/>
                <AddEntity visible={this.state.visibleEntity} onSuccess={async () => {
                    this.entityVisible(false);
                    await this.fetchEntity()
                }} onClose={() => this.entityVisible(false)}/>
                <AddTransAccount visible={this.state.visibleTrans} onSuccess={async () => {
                    this.transVisible(false);
                    await this.fetchTransAccount()
                }} onClose={() => this.transVisible(false)}/>
            </Layout>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        accounts: state.accounts,
        entities: state.entities,
        transAccount: state.transAccount,
        filters: state.filters
    }
};
export default connect(mapStateToProps, {getStatements})(AddIncome);