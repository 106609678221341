import React, {Component} from 'react';
import {Button, Checkbox, Divider, Input, Layout, Select} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import AddCategory from "./modal/AddCategory";
import {Image as Images} from "./Images";

const {Content} = Layout;
const {Option} = Select;
const {TextArea} = Input;

function handleChange(value) {
    console.log(`selected ${value}`);
}

function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
}


class EditIncome extends Component {
    state = {
        items: ['Category', 'Category'],
        name: '',
        visible: false,
    };

    onNameChange = event => {
        this.setState({
            name: event.target.value,
        });
    };
    categoryVisible = (visible) => {
        this.setState({
            visible: visible,
        })
    };

    render() {
        const {items} = this.state;
        return (
            <Layout className="site-layout site-layout-main ant-layout-main-second bg-white w-100 d-inline-block">
                <Content>
                    <div className="site-layout-background bg-white">
                        <div className="row heading-header-row">
                            <div className="col-12">
                                <h5 className="mb-0 font-weight-bold">
                                    Edit Income
                                </h5>
                            </div>
                        </div>
                        <div className="row title-details-row">
                            <div className="col-12 col-sm-4">
                                <div className="row modal-row-main">
                                    <form className="w-100">
                                        <div
                                            className="row mx-0 p-0 main-form-div bg-transparent rounded-0 shadow-none">
                                            <div className="col-12">
                                                <form>
                                                    <div className="form-group position-relative small-size">
                                                        <input type="text" placeholder=" "
                                                               className="form-control font-weight-normal"/>
                                                        <label className="mb-0">Title</label>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            <div className="col-12">
                                                <Select
                                                    placeholder="Category"
                                                    dropdownRender={menu => (
                                                        <div>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexWrap: 'nowrap',
                                                                padding: 8
                                                            }}>
                                                                <Button onClick={() => this.categoryVisible(true)}
                                                                        className="border-0 shadow-none edit-item-btn rounded-0 d-inline-block">
                                                                    <PlusOutlined/> Add Category
                                                                </Button>
                                                            </div>
                                                            <Divider style={{margin: '4px 0'}}/>
                                                            {menu}
                                                        </div>
                                                    )}
                                                >
                                                    {items.map(item => (
                                                        <Option key={item}>{item}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="col-12">
                                                <Select onChange={handleChange} placeholder="Account">
                                                    <Option value="jack">Account</Option>
                                                    <Option value="lucy">Account</Option>
                                                </Select>
                                            </div>
                                            <div className="col-12">
                                                <Select onChange={handleChange} placeholder="Entity">
                                                    <Option value="jack">Entity</Option>
                                                    <Option value="lucy">Entity</Option>
                                                </Select>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <TextArea rows={4} placeholder="Description"/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-12 mt-3 mb-4">
                                <Checkbox onChange={onChange}>Active Entry</Checkbox>
                            </div>
                            <div className="col-12">
                                <h6 className="list-heading-div font-weight-bold">Cashflow History</h6>
                            </div>
                            <div className="col-12" style={{borderTop: '1px solid #EDF1F4'}}>
                                <form className="w-100">
                                    <div className="row mx-0 main-form-div px-0 bg-transparent rounded-0 shadow-none">
                                        <form className="w-100">
                                            <div className="row">
                                                <div className="col-12 col-sm-4 col-md-4">
                                                    <div className="form-group position-relative small-size">
                                                        <input type="text" placeholder=" "
                                                               className="form-control font-weight-normal"/>
                                                        <label className="mb-0">Amount</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-4 col-md-4">
                                                    <div className="form-group position-relative small-size">
                                                        <input type="text" placeholder=" "
                                                               className="form-control small-size font-weight-normal"/>
                                                        <label className="mb-0">Date</label>
                                                        <span className="position-absolute icon-date-picker">
                                                            <img className="img-fluid" alt="icon"
                                                                 src={Images.calender_icon}/>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-4 col-md-4 modal-row-main">
                                                    <Select onChange={handleChange} placeholder="Repeat">
                                                        <Option value="jack">Never</Option>
                                                        <Option value="lucy">Never</Option>
                                                    </Select>
                                                </div>
                                                <div className="col-12 col-sm-4 col-md-4">
                                                    <div className="form-group position-relative small-size">
                                                        <input type="text" placeholder=" "
                                                               className="form-control font-weight-normal"/>
                                                        <label className="mb-0">Memo</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </form>
                            </div>
                            <div className="ant-modal-footer footer-custom bg-white">
                                <Button className="shadow-none">CANCEL</Button>
                                <Button className="shadow-none" type="primary">Update Income</Button>
                            </div>
                        </div>
                    </div>
                </Content>
                <AddCategory visible={this.state.visible} onClose={() => this.categoryVisible(false)}/>
            </Layout>
        );
    }
}

export default EditIncome;