import {
    GET_ALL_FILTER,
    RESET_FILTERS,
    UPDATE_ACCOUNT_FILTER,
    UPDATE_INCOME_CATEGORY_FILTER,
    UPDATE_EXPENSE_CATEGORY_FILTER,
    UPDATE_TRANSACTION_ACCOUNT_LIST,
    UPDATE_DATE_FILTER,
    UPDATE_ENTITY_FILTER,
    UPDATE_SEARCH_FILTER,
    UPDATE_STATS_TYPE,
    UPDATE_ASSET_TYPE,
    UPDATE_TRANSACTION_RESPONSIBILITY_LIST,
    UPDATE_LIQUIDITY_TYPE,
    UPDATE_INITIAL_AMOUNT_CHECKED
} from "../Actions/actionType";
import {defaultFilter} from '../../Components/helper';

const dates = defaultFilter()
const initialState = {
    account_list: null,
    entity_list: null,
    search: null,
    start_date: null,
    end_date: null,
    transaction_account_list: null,
    category_income_list: null,
    category_expense_list: null,
    stats_type: null,
    asset_type: null,
    liquidity_type: null,
    initial_amount_checked: 1,
    transaction_responsibility_list: null
};

// date YYYY-MM-DD
export default function filterReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_FILTER:
            return state;
        case UPDATE_ACCOUNT_FILTER: state = {
                ... state,
                account_list: action.payload
            };
            return state;
        case UPDATE_ENTITY_FILTER: state = {
                ... state,
                entity_list: action.payload
            };
            return state;
        case UPDATE_STATS_TYPE: state = {
                ... state,
                stats_type: action.payload
            };
            return state;
        case UPDATE_ASSET_TYPE: state = {
                ... state,
                asset_type: action.payload
            };
            return state;
        case UPDATE_LIQUIDITY_TYPE: state = {
                ... state,
                liquidity_type: action.payload
            };
            return state;
        case UPDATE_INITIAL_AMOUNT_CHECKED: state = {
                ... state,
                initial_amount_checked: action.payload
            };
            return state;
        case UPDATE_DATE_FILTER: state = {
                ... state,
                start_date: action.payload.start,
                end_date: action.payload.end
            };
            return state;
        case UPDATE_SEARCH_FILTER: state = {
                ... state,
                search: action.payload
            };
            return state;
        case UPDATE_INCOME_CATEGORY_FILTER: state = {
                ... state,
                category_income_list: action.payload
            };
            return state;
        case UPDATE_EXPENSE_CATEGORY_FILTER: state = {
                ... state,
                category_expense_list: action.payload
            };
            return state;
        case UPDATE_TRANSACTION_ACCOUNT_LIST: state = {
                ... state,
                transaction_account_list: action.payload
            };
            return state;
        case UPDATE_TRANSACTION_RESPONSIBILITY_LIST: state = {
                ... state,
                transaction_responsibility_list: action.payload
            };
            return state;
        case RESET_FILTERS: state = {
                account_list: null,
                entity_list: null,
                search: null,
                start_date: null,
                end_date: null,
                transaction_account_list: null,
                category_income_list: null,
                category_expense_list: null,
                stats_type: null,
                transaction_responsibility_list: null
            };
            return state;
        default:
            return state;
    }
}
