import { getEnvValue } from "./Environment";
import { include, reverse } from "named-urls";


const endpoint = {
    auth: include('/auth/', {
        login: 'login'
    }),
    category: include('/category/', {
        self: ''
    }),
    account: include('/account/', {
        self: ''
    }),
    entity: include('/entity/', {
        self: ''
    }),
    transAccount: include('/transaction-account/', {
        self: ''
    }),
    transResponsibility: include('/transaction-responsibility/', {
        self: ''
    }),
    updateAllStatement: "/update-all",
    syncAccountTransaction: "/sync-account-transaction",
    statement: include('/statement/', {
        self: '',
        id: ':id?/'
    }),
    plaid: include('/plaid/', {
        access_token: "access-token/",
        sync_plaid_statement: 'sync-plaid-statement/',
        create_link_token: 'create-link-token/',
        transactions: 'transactions/'
    }),
};


export const methods = {
    add: 'add',
    view: 'view',
    edit: 'edit'
};

export const payment = {
    income: 'income',
    expense: 'expense'
};

export function getAPIUrl(url, params = null) {
    const path = reverse(url.split('.').reduce((o, i) => o[i], endpoint), params);
    return getEnvValue('REACT_APP_API_URL') + path;
}
