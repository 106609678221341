import {ADD_ACCOUNT, GET_ACCOUNT} from "../Actions/actionType";

const initialState = [];


export default function accountReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ACCOUNT:
            state = [...action.payload];
            return state;
        case ADD_ACCOUNT:
            state = [...state, ...action.payload];
            return state;
        default:
            return state;
    }
}