import React, { Component } from 'react';
import { Tabs } from 'antd';
import { connect } from "react-redux";
import Charts from './Charts';
import ChartDetails from './ChartsDetails';
const { TabPane } = Tabs;


class CashFlowCharts extends Component {
    state = {
        graphData: {}
    }
    componentDidMount() {
        this.updateGraph()
    }
    componentDidUpdate(prevProps) {
        if (this.props.statement.data.length !== prevProps.statement.data.length) {
            this.updateGraph();
        }
    }
    updateGraph = () => {
        var output = []
        if (this.props.statement.data) {
            const group = this.props.statement.data.reduce((acc, item) => {
                if (!acc[item.stats_type]) acc[item.stats_type] = [];
                item.amount = parseFloat(item.amount)
                acc[item.stats_type].push(item);
                return acc;
            }, {})
            Object.keys(group).map((_) => {
                output = group[_].reduce(function (o, cur) {
                    var occurs = o.reduce(function (n, item, i) {
                        return (item.category.name === cur.category.name) ? i : n;
                    }, -1);
                    if (occurs >= 0) {
                        o[occurs].amount = parseFloat(o[occurs].amount) + parseFloat(cur.amount);
                    } else {
                        var obj = {
                            ...cur,
                            name: cur.category.name,
                            amount: cur.amount
                        };
                        o = o.concat([obj]);
                    }
                    return o;
                }, []);
                group[_] = output
            })
            this.setState({ graphData: group })
        }
    }
    render() {
        return (
            <div className="chart-container">
                <div className="row">
                    <div className="col-md-6 col-sm-6"></div>
                    <div className="col-md-6 col-sm-6">
                        <ul className="cc-graph-tabs">
                            <li>Distribution</li>
                            <li>Balance</li>
                            <li>Day to Day</li>
                        </ul>
                    </div>
                </div>
                <div className="row mx-0 chart-tabs">
                    <Tabs defaultActiveKey="1" tabBarStyle={{ width: '100%' }} >
                        <TabPane tab="Table" key="1">
                            <ChartDetails {...this.state} />
                        </TabPane>
                        <TabPane tab="Graph" key="2">
                            <Charts {...this.state} graphData={this.state.graphData} />
                        </TabPane>
                    </Tabs>
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return { statement: state.statements.statement, filters: state.filters }
};
export default connect(mapStateToProps)(CashFlowCharts);

