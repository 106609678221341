import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PlaidLink} from "react-plaid-link";
import {plaidAccessTokenStatement, plaidLinkTokenStatement} from "../../Controller/API/PaymentAPI";
import {Button} from "antd";
import GetTransaction from "./GetTransaction";

class PlaidPopup extends Component {
  state = {
    transactionPopup: false,
    linkToken: "",
    access_token: "",
    previous_access_token: ""
  }
  onSuccess = (public_token, metadata) => {
    const {previous_access_token} = this.state
    if (!previous_access_token) {
      plaidAccessTokenStatement({public_token})
        .then(response => {
          this.setState({access_token: response.data.access_token})
        })
        .catch(err => {
          this.setState({
            linkToken: err.response.data.link_token[0],
            previous_access_token: err.response.data.access_token[0]
          })
        })
    } else {
      this.setState({access_token: previous_access_token})
    }
  }

  getPlaidLinkToken = () => {
    plaidLinkTokenStatement()
      .then(response => {
        this.setState({linkToken: response.data.link_token})
      })
  }

  componentDidMount() {
    this.getPlaidLinkToken()
  }

  onExit = () => {
    const {previous_access_token} = this.state
    if (previous_access_token) {
      this.setState({access_token: previous_access_token})
    }
  }

  onLoad = (e) => {
  }

  onEvent = (e) => {
  }

  handleTransactionPopup = (status) => {
    this.setState({transactionPopup: status})
  }
  disconnectBank = () => {
    this.setState({
      transactionPopup: false,
      linkToken: "",
      access_token: "",
      previous_access_token: ""
    })
    const _this = this
    setTimeout(function () {
      _this.getPlaidLinkToken()
    }, 1000);
  }

  render() {
    const {linkToken, access_token, transactionPopup, previous_access_token} = this.state;
    return (
      <div>
        {access_token ?
          <>
            <Button className='plaid-btn' onClick={() => this.handleTransactionPopup(true)}>Transactions</Button>
            <Button className='plaid-btn' onClick={this.disconnectBank}>Disconnect Bank</Button>
            {transactionPopup ?
              <GetTransaction access_token={access_token} visible={transactionPopup}
                              onClose={() => this.handleTransactionPopup(false)}/>
              : ""}
          </>
          :
          linkToken ?
            <PlaidLink onEvent={this.onEvent} onLoad={this.onLoad} className='plaid-btn' onSuccess={this.onSuccess}
                       onExit={this.onExit} token={linkToken}>{previous_access_token? "Reconnect account": "Connect a bank account"}</PlaidLink> : ""
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
)(PlaidPopup);
