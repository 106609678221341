import {ADD_TRANS_ACCOUNT, GET_TRANS_ACCOUNT} from "../Actions/actionType";

const initialState = [];

export default function entityReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TRANS_ACCOUNT:
            state = [...action.payload];
            return state;
        case ADD_TRANS_ACCOUNT:
            state = [...state, ...action.payload];
            return state;
        default:
            return state;
    }
}