export const USER_TOKEN = 'user_token';
export const PLAID_ACCESS_TOKEN = 'cashflow_plaid_access_token';

export function logout() {
  clearUserToken();
}


export function setUserToken(TOKEN) {
  localStorage.setItem(USER_TOKEN, TOKEN);
}

export function getUserToken() {
  return localStorage.getItem(USER_TOKEN);
}

export function clearUserToken() {
  localStorage.removeItem(USER_TOKEN);
}

export function isLoggedIn() {
  const accessToken = getUserToken();
  return !!accessToken;
}

export function setPlaidAccessToken(token) {
  localStorage.setItem(PLAID_ACCESS_TOKEN, token)
}

export function getPlaidAccessToken() {
  return localStorage.getItem(PLAID_ACCESS_TOKEN);
}

export function clearPlaidAccessToken() {
  localStorage.removeItem(PLAID_ACCESS_TOKEN);
}
