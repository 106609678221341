import React, { Component } from 'react';
import { Input, message, Modal } from "antd";
import { addTransAccount } from "../../Controller/API/PaymentAPI";
import { connect } from "react-redux";
import { addTransAction, getTransAction } from "../../Redux/Actions/transAccountAction";

class AddTransAccount extends Component {
    state = {
        name: ''
    };
    addTransAccount = () => {
        const { name } = this.state;
        if (name === "") {
            message.error('Entity Name is required')
        }
        let data = {
            name,
        };
        addTransAccount(data).then(response => {
            if (response.status === 201) {
                message.success('Entity Added Successfully!');
                this.props.getTransAction();
                this.props.onClose();
                this.setState({ name: '' })
            }
        }).catch(err => {
            message.error('Error while adding Entity!')
        })
    };

    render() {
        return (
            <div>
                <Modal
                    title="Add Transaction Account"
                    destroyOnClose={true}
                    visible={this.props.visible}
                    onOk={this.addTransAccount}
                    onCancel={this.props.onClose}
                    width="26%"
                    cancelText="cancel"
                    okText="Add Transaction Account"
                >
                    <div
                        className="row mx-0 p-0 main-form-div bg-transparent rounded-0 shadow-none">
                        <div className="col-12">
                            <form>
                                <div className="form-group position-relative small-size">
                                    <Input onChange={e => this.setState({ name: e.target.value })} type="text"
                                        placeholder="Transaction Account Name"
                                        className="form-control font-weight-normal" />
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        transAccount: state.transAccount
    }
};
export default connect(mapStateToProps, { getTransAction, addTransAction })(AddTransAccount);