import React, { Component } from 'react';
import { Checkbox, Layout, Table } from "antd";
import { Image as Images } from "./Images";
import { Link } from "react-router-dom";

const { Content } = Layout;

function onChange(e) {
    // console.log(`checked = ${e.target.checked}`);
}


const columns = [
    {
        title: 'Date',
        dataIndex: 'date',
    },
    {
        title: 'Title',
        dataIndex: 'title',
    },
    {
        title: 'Payment',
        dataIndex: 'payment',
    }, {
        title: 'Deposit',
        dataIndex: 'deposit',
    }, {
        title: 'Balance',
        dataIndex: 'balance',
    },
];

const data = [
    {
        key: '1',
        date: <div className="gray-color-custom">
            Sat, Feb 01, 2020
        </div>,
        title: <div className="position-relative pl-4">
            <span className="position-absolute tag-div rounded-circle mx-auto" />
            Dovecote Accounting Services
        </div>,
        payment: <div className="text-danger">
            $7,420.12
        </div>,
        deposit: <div className="text-success" />,
        balance: <div>$734,209.20</div>,
    },
    {
        key: '2',
        date: <div className="gray-color-custom">
            Sat, Feb 01, 2020
        </div>,
        title: <div className="position-relative pl-4">
            <span className="position-absolute tag-div rounded-circle mx-auto" />
            Dovecote Accounting Services
        </div>,
        payment: <div className="text-danger" />,
        deposit: <div className="text-success">$10,092.22</div>,
        balance: <div>$734,209.20</div>,
    },
    {
        key: '3',
        date: <div className="gray-color-custom">
            Sat, Feb 01, 2020
        </div>,
        title: <div className="position-relative pl-4">
            <span className="position-absolute tag-div rounded-circle mx-auto" />
            Dovecote Accounting Services
        </div>,
        payment: <div className="text-danger" />,
        deposit: <div className="text-success">$10,092.22</div>,
        balance: <div>$734,209.20</div>,
    }, {
        key: '4',
        date: <div className="gray-color-custom">
            Sat, Feb 01, 2020
        </div>,
        title: <div className="position-relative pl-4">
            <span className="position-absolute tag-div rounded-circle mx-auto" />
            Dovecote Accounting Services
        </div>,
        payment: <div className="text-danger" />,
        deposit: <div className="text-success">$10,092.22</div>,
        balance: <div>$734,209.20</div>,
    }, {
        key: '5',
        date: <div className="gray-color-custom">
            Sat, Feb 01, 2020
        </div>,
        title: <div className="position-relative pl-4">
            <span className="position-absolute tag-div rounded-circle mx-auto" />
            Dovecote Accounting Services
        </div>,
        payment: <div className="text-danger">$7,420.12</div>,
        deposit: <div className="text-success" />,
        balance: <div>$734,209.20</div>,
    }, {
        key: '6',
        date: <div className="gray-color-custom">
            Sat, Feb 01, 2020
        </div>,
        title: <div className="position-relative pl-4">
            <span className="position-absolute tag-div rounded-circle mx-auto" />
            Dovecote Accounting Services
        </div>,
        payment: <div className="text-danger">$7,420.12</div>,
        deposit: <div className="text-success" />,
        balance: <div>$734,209.20</div>,
    }, {
        key: 7,
        date: <div className="gray-color-custom">
            Sat, Feb 01, 2020
        </div>,
        title: <div className="position-relative pl-4">
            <span className="position-absolute tag-div rounded-circle mx-auto" />
            Dovecote Accounting Services
        </div>,
        payment: <div className="text-danger">$7,420.12</div>,
        deposit: <div className="text-success" />,
        balance: <div>$734,209.20</div>,
    }, {
        key: '8',
        date: <div className="gray-color-custom">
            Sat, Feb 01, 2020
        </div>,
        title: <div className="position-relative pl-4">
            <span className="position-absolute tag-div rounded-circle mx-auto" />
            Dovecote Accounting Services
        </div>,
        payment: <div className="text-danger" />,
        deposit: <div className="text-success">$10,092.22</div>,
        balance: <div>$734,209.20</div>,
    },
];

class AccountTitle extends Component {
    render() {
        return (
            <Layout className="site-layout site-layout-main ant-layout-main-second bg-white w-100 d-inline-block">
                <Content>
                    <div className="site-layout-background bg-white">
                        <div className="row heading-header-row">
                            <div className="col-md-6 col-sm-6 col-8 col-lg-6">
                                <h5 className="mb-0 font-weight-bold pl-4 position-relative">
                                    <span className="position-absolute tag-div tag-w-h-inc rounded-circle my-auto" />
                                    “Bank Account Title”
                                </h5>
                            </div>
                            <div className="col-md-6 col-sm-6 col-4 col-lg-6">
                                <div className="row mx-0 justify-content-end">
                                    <Link to="/dashboard/account-title/add-account/"
                                        className="bg-transparent ant-btn flex-align-center-center border-0 text-uppercase rounded-0 shadow-none">
                                        <img src={Images.edit_icon} className="img-fluid mr-1" alt="edit icon" />
                                        EDIT</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row title-details-row">
                            <div className="col-sm-4 col-6 col-md-4 col-lg-4 mb-3">
                                <h6 className="w-100 mb-1">Currency</h6>
                                <p className="mb-0">USD</p>
                            </div>
                            <div className="col-12 mb-4">
                                <Checkbox onChange={onChange}>Default Bank Account</Checkbox>
                            </div>
                            <div className="col-12">
                                <h6 className="list-heading-div font-weight-bold">Cashflow History</h6>
                            </div>
                            <div className="col-12">
                                <div className="row mx-0 listing-table table-responsive">
                                    <Table
                                        className="responsive-table table table-hover table-custom"
                                        columns={columns}
                                        dataSource={data}
                                        bordered
                                        pagination={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </Layout>
        );
    }
}

export default AccountTitle;