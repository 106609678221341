import React, {Component} from 'react';
import {Checkbox, message, Table} from "antd";
import {history} from "../Controller/history";
import {getStatements} from "../Redux/Actions/statementAction";
import {reverse} from "named-urls/src";
import {routes} from "../Controller/routes";
import {methods} from "../Controller/Global";
import moment from "moment";
import {getAllFilters, resetFilters, updateDateFilter} from "../Redux/Actions/filterAction";
import {
    getCategory,
    getEntity,
    getTransAccount,
    getTransResponsibility,
    updateAllStatements
} from "../Controller/API/PaymentAPI";
import getDropdownSearchProps from "./ColumnDropdown/ColumnDropdown";
import {connect} from "react-redux";


class CashFlowTable extends Component {
    state = {
        selectedRowKeys: [],
        categories: [],
        category: null,
        responsibilities: [],
        responsibility: null,
        transaction_accounts: [],
        transaction_account: null,
        entities: [],
        entity: null,
        reloadTable: false,
        active_entry: false

    }

    onRowValueChange = (value, type) => {
        switch (type) {
            case "Category":
                this.setState({category: value})
                break;
            case "Responsibility":
                this.setState({responsibility: value})
                break;
            case "Transaction Account":
                this.setState({transaction_account: value})
                break;
            case "Entity":
                this.setState({entity: value})
                break;
            case "Active Entry":
                this.setState({active_entry: value})
                break;
        }
    }
    getColumns = () => {
        return [
            {
                title: 'Date',
                dataIndex: 'date',
                render: (data) => {
                    return moment(data).format('ddd, MMM DD, YYYY')
                }
            },

            {
                title: 'Description',
                dataIndex: 'description',
                // width:'200px'
            },
            {
                title: 'Category',
                dataIndex: 'category',
                render: (category) => {
                    return category.name
                },
                ...getDropdownSearchProps(
                    'Category',
                    this.fetchCategory,
                    this.fetchCategory,
                    this.state.categories,
                    this.onRowValueChange,
                    this.appleCategoryDataOnSelectedRows
                ),

            },
            {
                title: 'Transaction Holder',
                dataIndex: 'transaction_responsibility',
                render: (res) => {
                    return res ? res.name : "-"
                },
                ...getDropdownSearchProps(
                    'Responsibility',
                    this.fetchResponsibility,
                    this.fetchResponsibility,
                    this.state.responsibilities,
                    this.onRowValueChange,
                    this.appleResponsibilityDataOnSelectedRows
                )

            },
            {
                title: 'Transaction Account',
                dataIndex: 'transaction_account',
                render: (transaction) => {
                    return transaction ? transaction.name : "-"
                },
                ...getDropdownSearchProps(
                    'Transaction Account',
                    this.fetchTransAccount,
                    this.fetchTransAccount,
                    this.state.transaction_accounts,
                    this.onRowValueChange,
                    this.appleTransAccountDataOnSelectedRows
                )
            },
            {
                title: 'Entity',
                dataIndex: 'entity',
                render: (entity) => {
                    return entity.name
                },
                ...getDropdownSearchProps(
                    'Entity',
                    this.fetchEntity,
                    this.fetchEntity,
                    this.state.entities,
                    this.onRowValueChange,
                    this.appleEntityDataOnSelectedRows
                )
            },
            {
                title: 'Payment',
                dataIndex: 'payment',
                render: (data, record) => {
                    return <div className="text-danger">{record.stats_type === 'EXPENSE' ? record.amount : ""}</div>
                }
            }, {
                title: 'Deposit',
                dataIndex: 'deposit',
                render: (data, record) => {
                    return <div className="text-success">{record.stats_type === 'INCOME' ? record.amount : ""}</div>
                }
            }, {
                title: 'Balance',
                dataIndex: 'balance',
                render: (data) => {
                    return <div>{data}</div>
                }
            }, {
                title: 'Active Entry',
                dataIndex: 'active_entry',
                render: (data) => {
                    return <div><Checkbox checked={data}></Checkbox></div>
                },
                ...getDropdownSearchProps(
                    'Active Entry',
                    () => [{"id": true, "name": "Enable"}, {"id": false, "name": "Disable"}],
                    () => [{"id": true, "name": "Enable"}, {"id": false, "name": "Disable"}],
                    [{"id": true, "name": "Enable"}, {"id": false, "name": "Disable"}],
                    this.onRowValueChange,
                    this.appleActiveEntryDataOnSelectedRows
                )
            },
        ]
    }

    onSelectChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };

    showIncomeDetails = (record) => {
        history.push(reverse(routes.dashboard.payment.method, {
            type: record.stats_type.toLowerCase(),
            method: methods.view,
            id: record.id
        }))
    };

    async componentDidMount() {
        if (!this.props.statement.data.length) {
            await this.props.getStatements(this.props.filters);
        }
    }

    fetchCategory = (params = {}) => {
        getCategory(params)
            .then(response => {
                this.setState({categories: response.data})
            }).catch(() => {
            message.error('Something went wrong')
        })
    };

    fetchResponsibility = (params = {}) => {
        getTransResponsibility(params)
            .then(response => {
                this.setState({responsibilities: response.data})
            }).catch(() => {
            message.error('Something went wrong')
        })
    };
    fetchTransAccount = (params = {}) => {
        getTransAccount(params)
            .then(response => {
                this.setState({transaction_accounts: response.data})
            }).catch(() => {
            message.error('Something went wrong')
        })
    };
    fetchEntity = (params = {}) => {
        getEntity(params)
            .then(response => {
                this.setState({entities: response.data})
            }).catch(() => {
            message.error('Something went wrong')
        })
    };

    appleCategoryDataOnSelectedRows = () => {
        const {selectedRowKeys, category} = this.state
        const data = {
            statements: selectedRowKeys,
            item_type: "Category",
            item_id: category
        }
        if (selectedRowKeys.length > 0) {
            this.updateTableData(data, {category: null})
        }
    }
    appleResponsibilityDataOnSelectedRows = () => {
        const {selectedRowKeys, responsibility} = this.state
        const data = {
            statements: selectedRowKeys,
            item_type: "Responsibility",
            item_id: responsibility
        }
        if (selectedRowKeys.length > 0) {
            this.updateTableData(data, {responsibility: null})
        }
    }
    appleTransAccountDataOnSelectedRows = () => {
        const {selectedRowKeys, transaction_account} = this.state
        const data = {
            statements: selectedRowKeys,
            item_type: "Transaction Account",
            item_id: transaction_account
        }
        if (selectedRowKeys.length > 0) {
            this.updateTableData(data, {transaction_account: null})
        }
    }
    appleEntityDataOnSelectedRows = () => {
        const {selectedRowKeys, entity} = this.state
        const data = {
            statements: selectedRowKeys,
            item_type: "Entity",
            item_id: entity
        }
        if (selectedRowKeys.length > 0) {
            this.updateTableData(data, {entity: null})
        }
    }
    appleActiveEntryDataOnSelectedRows = () => {
        const {selectedRowKeys, active_entry} = this.state
        const data = {
            statements: selectedRowKeys,
            item_type: "Active Entry",
            item_id: 0,
            active_entry: active_entry
        }
        if (selectedRowKeys.length > 0) {
            this.updateTableData(data, {active_entry: false})
        }
    }
    updateTableData = (data, item_type) => {
        this.setState({reloadTable: true})
        updateAllStatements(data)
            .then(() => {
                this.setState(item_type)
                this.props.getStatements(this.props.filters, this.props.cancelSource);
            })
            .finally(() => {
                this.setState({reloadTable: false})
            })
    }


    render() {
        const {selectedRowKeys, reloadTable} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className="row mx-0 listing-table table-responsive custom-checkbox-table">
                {!reloadTable ?
                    <Table scroll={{y: 550}} pagination={{defaultPageSize: 25}}
                           onRow={(record) => {
                               return {
                                   onClick: () => {
                                       if (record.id) {
                                           this.showIncomeDetails(record)
                                       }
                                   }
                               };
                           }}
                           rowSelection={rowSelection}
                           rowKey={record => record.id}
                           className="responsive-table table table-hover table-custom  detail-table"
                           columns={this.getColumns()}
                           dataSource={this.props.statement.data}
                           loading={this.props.statement.loading}
                           locale={{'emptyText': "No data available"}}
                           bordered
                    /> : ""}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {statement: state.statements.statement, filters: state.filters, cancelSource: state.statements.cancelSource}
};
export default connect(mapStateToProps, {getStatements, getAllFilters, resetFilters, updateDateFilter})(CashFlowTable);
