import {include} from 'named-urls'

export const routes = {
    login: '/',
    logout: '/logout/',
    dashboard: include('/dashboard', {
        self: '',
        payment: include('payment', {
            self: '',
            method: ':type/:method/:id?/',
        }),
        account: include('account', {
            self: '',
            method: ':method/:id?/'
        }),

    })
};