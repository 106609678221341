import logo_login_screen from '../assets/img/erdos-cube-logo.png'
import logo_sidebar_small from '../assets/img/Erdos-Cube-small-logo.png'

import plus_green from '../assets/img/plus.png'
import user_icon_header from '../assets/img/user-icon.png'
import edit_icon from '../assets/img/edit-icon.png'
import calender_icon from '../assets/img/icon-calender.png'

export let Image = {
    logo_login_screen, logo_sidebar_small, plus_green, user_icon_header, edit_icon, calender_icon
};