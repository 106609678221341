import * as constant from '../Actions/actionType';

const initialState = {
    statement: {
        data: [],
        loading: false,
    }
};

export default function statementReducer(state = initialState, action) {
    switch (action.type) {
        case constant.GET_STATEMENTS:
            return {
                ...state,
                statement: {
                    data: [],
                    loading: true,
                }
            }
        case constant.GET_STATEMENTS_SUCCESS:
            return {
                ...state,
                statement: {
                    data: [...action.payload],
                    loading: false,
                },
            }
        case constant.CANCEL_SOURCE:
            return {
                ...state,
                cancelSource: action.cancelSource
            }
        default:
            return state
    }
}
